import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { compose } from "recompose";

import teaserNodeQueryEvent from "../../../../teaser-queries/teaser-node-query-event.graphql";
import teaserNodeQueryEventTagFiltered from "../../../../teaser-queries/teaser-node-query-event-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserEvent from "../../../../teaser/teaser-event";
import Masonry from "react-masonry-component";

moment.locale("de");

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
    adminApp: reduxStore.appStore.adminApp,
    microSite: reduxStore.appStore.microSite,
  }),
  TeaserEventList = (props) => (
    <>
      {!props.microSite ? (
        <>
          {props.nodesConfig === "Manuell" ? (
            <>
              {props.manualNodes.map((item, index) => (
                <TeaserEvent key={index} item={item.entity} />
              ))}
            </>
          ) : (
            <>
              {props.nodes.nodeQuery &&
              props.nodes.nodeQuery.entities &&
              props.nodes.nodeQuery.entities.length > 0 ? (
                <>
                  {props.nodes.nodeQuery.entities.map((item, index) => (
                    <TeaserEvent key={index} item={item} />
                  ))}
                </>
              ) : (
                <LoadingIndicator />
              )}
            </>
          )}
        </>
      ) : (
        <div className={"highlighted-events"}>
          <div className="container">
            <Masonry className={'row masonry-wrapper'} elementType={"div"}>
              {props.nodesConfig === "Manuell" ? (
                <>
                  {props.manualNodes.map((item, index) => (
                    <TeaserEvent key={index} item={item.entity} />
                  ))}
                </>
              ) : (
                <>
                  {props.nodes.nodeQuery &&
                  props.nodes.nodeQuery.entities &&
                  props.nodes.nodeQuery.entities.length > 0 ? (
                    <>
                      {props.nodes.nodeQuery.entities.map((item, index) => (
                        <TeaserEvent key={index} item={item} />
                      ))}
                    </>
                  ) : (
                    <LoadingIndicator />
                  )}
                </>
              )}
            </Masonry>
          </div>
        </div>
      )}
    </>
  );

TeaserEventList.propTypes = {
  count: PropTypes.number,
  manualNodes: PropTypes.array,
  nodes: PropTypes.object.isRequired,
  nodesConfig: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
};

export default compose(
  graphql(teaserNodeQueryEvent, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length > 0,
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: props.count ? props.count : 100,
        type: [props.type],
        filterKanal: props.kanalFilter ? true : false,
        kanal: props.kanalFilter ? props.kanalFilter.entity.entityId : null
      },
    }),
  }),
  graphql(teaserNodeQueryEventTagFiltered, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length === 0,
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: props.count ? props.count : 100,
        type: [props.type],
        tags: props.tags.map((item) => item.targetId.toString()),
        filterKanal: props.kanalFilter ? true : false,
        kanal: props.kanalFilter ? props.kanalFilter.entity.entityId : null
      },
    }),
  })
)(connect(mapStateToProps)(withRouter(TeaserEventList)));
