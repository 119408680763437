import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from "../../../../general-components/image";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = reduxStore => ({
  isMobile: reduxStore.appStore.isMobile
});

class ParagraphText extends Component {

  textWrapper = React.createRef();

  state = {
    activeIndex: 0
  }

  mouseOverVerweis = (moduleWrapper, verweise, index) => {
    let verweiseImages = verweise.querySelectorAll('.verweis');

    if (verweiseImages[index]) {
      verweiseImages[index].classList.add('active');
      verweiseImages[index].style.zIndex = this.state.activeIndex + 1;

      this.setState({
        activeIndex: this.state.activeIndex + 1
      });
      if (this.props.isMobile) {
        setTimeout(() => {
          this.closeVerweis();
        }, 2000);
      }
    }
  }

  initializeVerweise = () => {
    let verweise = this.textWrapper.current.querySelector('.verweise-wrapper');

    if (verweise) {
     let moduleWrapper = this.textWrapper.current.querySelector('.verweise-wrapper').closest('.paragraph-cloud-view'),
       links = this.textWrapper.current.querySelectorAll('.vw');

     if(moduleWrapper && links) {
       moduleWrapper.appendChild(verweise);
       links && [...links].map((link, index) => {
         link.addEventListener('mouseenter', event => this.mouseOverVerweis(moduleWrapper, verweise, index));
       });
     }
    }
  }

  closeVerweis = () => {
    let verweis = document.querySelector('.verweise-wrapper .verweis.active');
    if (verweis) {
      verweis.classList.remove('active');
    }
  }

  componentDidMount() {
    if (this.textWrapper.current.querySelector('.verweise-wrapper')) {
      setTimeout(() => {
        this.initializeVerweise();
      }, 500);
    }
  }

  render() {
    const sectionClassNames = classNames({
      'paragraph paragraph-text': true,
      'background-color': this.props.content.fieldFarbigHinterlegen
    });

    return (
      <section className={sectionClassNames} ref={this.textWrapper} onMouseLeave={() => this.closeVerweis()}>
        <div className="container">
          <div className="row">
            <div className="col-wrapper col-16">
              {this.props.content.fieldText &&
                <Hyphenated>
                  <div className="text" dangerouslySetInnerHTML={{ __html: this.props.content.fieldText.processed }} />
                </Hyphenated>
              }
            </div>
          </div>
        </div>
        {this.props.content.fieldVerweise &&
          <div className="verweise-wrapper">
            <div className="fixed-wrapper">
              {this.props.content.fieldVerweise.map((verweis, index) =>
                <div className="verweis" key={index}>
                  {verweis.entity.fieldImage &&
                    <div className="image-wrapper">
                      {/*<img className="close" src={`/RAD-Schliessen-Button.svg`}/>*/}
                      <Image data={verweis.entity.fieldImage.entity.fieldMediaImage}/>
                      {verweis.entity.fieldImage.entity.fieldCredit &&
                        <span className="credit">© {verweis.entity.fieldImage.entity.fieldCredit}</span>
                      }
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        }
      </section>
    );
  }
}

ParagraphText.propTypes = {
  content: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ParagraphText);