import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, FieldArray } from "formik";
import classNames from "classnames";
import { states } from "../states";
import WebformElements from "../webform-elements";

const CompositeField = ({ formik, item, token, generatedInitialValues }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  const initialValue = formik.values[item.id];

  return (
    <div
      className={classNames({
        "form-group custom-composite": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        <h3>
          {item.title}
          {(!!item.required || required) && !optional && visible && (
            <span className="required">*</span>
          )}
        </h3>
      </label>

      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}

      {/* @see https://formik.org/docs/api/fieldarray */}
      <FieldArray
        name={item.id}
        render={(arrayHelpers) => (
          <>
            <div className="form-row">
              {[...Array(initialValue.length)].map((i, index) => (
                <div
                  key={index}
                  className="col-16 col-md-8"
                  style={{
                    padding: "1em",
                    border: "1px solid grey",
                  }}
                >
                  <WebformElements
                    formik={formik}
                    items={item.elements}
                    token={token}
                    compositeIndex={index}
                    compositeParent={item.id}
                  />
                  <div style={{ marginTop: "1em" }}>
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      Element entfernen
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {!!item.multiple &&
            (item.multiple.limit === 0 ||
              item.multiple.limit > initialValue.length) ? (
              <div style={{ marginTop: "1em" }}>
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push(generatedInitialValues[item.id][0])
                  }
                >
                  Element hinzufügen
                </button>
              </div>
            ) : null}
          </>
        )}
      />

      <ErrorMessage component="span" name={item.id} className="error-message" />
    </div>
  );
};

CompositeField.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  generatedInitialValues: PropTypes.object,
};

export default CompositeField;
