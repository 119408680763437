import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import classNames from "classnames";
import { states } from "../states";
import WebformElements from "../webform-elements";
const Details = ({ formik, item, token }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  return (
    <details
      className={classNames({
        "form-group webform-details": true,
        hidden: invisible || !visible,
      })}
      open={!!item.open}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <summary>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </summary>

      <WebformElements formik={formik} items={item.elements} token={token} />

      <ErrorMessage component="span" name={item.id} className="error-message" />

      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </details>
  );
};

Details.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default Details;
