import React, { Component } from "react";
import PropTypes from "prop-types";
import Vimeo from "@u-wave/react-vimeo";

export const VimeoVideo = (props) => (
  <section className={props.sectionClassNames}>
    {props.content.fieldGroesse === "0" && (
      <div className="full-width">
        <Vimeo
          video={props.content.fieldVimeoVideoUrl}
          responsive
          controls={true}
        />
      </div>
    )}
    {props.content.fieldGroesse === "1" && (
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-8 offset-lg-4 video-wrapper">
            <Vimeo video={props.content.fieldVimeoVideoUrl} responsive />
          </div>
        </div>
      </div>
    )}
  </section>
);

VimeoVideo.propTypes = {
  content: PropTypes.object.isRequired,
  sectionClassNames: PropTypes.string,
};
