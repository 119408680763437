import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class ComponentFilterCloud extends Component {
  static defaultProps = { wrap: true };

  getTagsFromItems = (items) => {
    let tags = [];

    for (const item of items) {
      for (const tag of item[`${this.props.filter}`]) {
        if (
          tag.entity !== null &&
          !tags.some(({ entityId }) => entityId === tag.entity.entityId)
        ) {
          tags = [...tags, tag.entity];
        }
      }
    }

    return tags;
  };

  state = {
    tags: this.props.items ? this.getTagsFromItems(this.props.items) : [],
  };

  render() {
    const filter = (
      <>
        {this.state.tags.map((item, index) => (
          <div
            className={classNames({
              filter: true,
              active:
                this.props.activeFilter &&
                this.props.activeFilter.entityId === item.entityId,
            })}
            key={index}
            onClick={() => this.props.changeActiveFilter(item)}
          >
            <span>{item.name}</span>
          </div>
        ))}
      </>
    );

    if (this.props.wrap) {
      return (
        <section className="filter-cloud">
          <div className="container">
            <div className="row">
              <div className="col-16 d-flex">{filter}</div>
            </div>
          </div>
        </section>
      );
    }

    return (
      <section className="filter-cloud">
        <div className="d-flex">{filter}</div>
      </section>
    );
  }
}

ComponentFilterCloud.propTypes = {
  activeFilter: PropTypes.object,
  changeActiveFilter: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  wrap: PropTypes.bool.isRequired,
};

export default ComponentFilterCloud;
