import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

import ErrorBoundary from "../../../../../error-boundary";
import ComponentClickSuggestionLink from "../../component-click-suggestion-link";
import TeaserGeneral from "../../../../teaser/teaser-general";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";

const sliderSettings = {
  adaptiveHeight: false,
  arrows: true,
  nextArrow: (
    <div className="slick-arrow slick-next">
      <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />
    </div>
  ),
  prevArrow: (
    <div className="slick-arrow slick-prev">
      <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />
    </div>
  ),
  centerMode: false,
  dots: false,
  infinite: false,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  touchMove: true,
  draggable: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        touchMove: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        touchMove: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        touchMove: true,
        swipeToSlide: true,
      },
    },
  ],
};

class ParagraphTeaserListPages extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-list paragraph-teaser-pages": true,
      "paragraph paragraph-teaser-list-slick":
        this.props.content.fieldBeitraege.length >= 4,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16">
                <Hyphenated language={de}>
                  <h2>{this.props.content.fieldHeading}</h2>
                </Hyphenated>
              </div>
            </div>
          </div>
        )}

        {this.props.content.fieldBeitraege.length >= 4 ? (
          <Slider {...sliderSettings}>
            {this.props.content.fieldBeitraege.map((item, index) => (
              <TeaserGeneral item={item.entity} key={index} />
            ))}
          </Slider>
        ) : (
          <div className="container">
            <div className="row">
              {this.props.content.fieldBeitraege.map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    [`col-${
                      32 / this.props.content.fieldBeitraege.length
                    } col-lg-${16 / this.props.content.fieldBeitraege.length}`]:
                      this.props.content.fieldBeitraege.length !== 3,
                    "col-16 col-lg-5":
                      this.props.content.fieldBeitraege.length === 3 &&
                      index !== 0,
                    "col-16 col-lg-5 offset-lg-1":
                      this.props.content.fieldBeitraege.length === 3 &&
                      index === 0,
                  })}
                >
                  <TeaserGeneral item={item.entity} />
                </div>
              ))}
            </div>
          </div>
        )}

        {this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton.title && (
            <div className="container">
              <div className="row">
                <div className="col-16 d-md-flex justify-content-md-end">
                  <ErrorBoundary>
                    <ComponentClickSuggestionLink
                      link={this.props.content.fieldMehrMeldungenButton}
                      className="btn btn-primary more"
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphTeaserListPages.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphTeaserListPages;
