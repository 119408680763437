import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastNotification } from "carbon-components-react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { restHostBackend, self } from "../config";
import { authenticationLoginAction } from "./redux-authentication";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class UserStatusBlock extends Component {
  state = {
    error: "",
  };

  logout = () => {
    axios
      .post(
        `/api/v1/logout`,
        {
          access_token: this.props.auth.userLoginData.access_token,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then((response) => {
        this.props.dispatch(authenticationLoginAction({}));
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message,
        });

        this.props.dispatch(authenticationLoginAction({}));
      });
  };

  render() {
    if (this.state.error !== "") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="user-status-block">
                <ToastNotification
                  kind="error"
                  title="Authentication Error"
                  subtitle={this.state.error}
                  caption={<a href="/">Neu laden</a>}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.auth.userLoginData.logout_token) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="user-status-block">
                <ToastNotification
                  kind="info"
                  caption={
                    <>
                      <Link to="/intern/intern-willkommen">Startseite interner Bereich</Link>
                      <br />
                      <br />
                      <a onClick={() => this.logout()} href="#">
                        Logout
                      </a>
                    </>
                  }
                  title="Login Status"
                  subtitle={`Sie sind angemeldet als ${
                    this.props.auth.userLoginData.current_user.name
                  } (ID: ${
                    this.props.auth.userLoginData.current_user.uid
                  }). Sie werden automatisch am ${moment
                    .unix(this.props.auth.userLoginData.jwt.exp)
                    .format("DD.MM.YY HH:mm")} ausgeloggt.`}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="user-status-block">
              <ToastNotification
                kind="info"
                caption={<Link to="/app/login">Login</Link>}
                title="Login Status"
                subtitle={"Sie sind nicht angemeldet"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserStatusBlock.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UserStatusBlock);
