import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

class ParagraphPageTitle extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-page-title": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-12">
              {this.props.content.fieldAutomatischTrennen ? (
                <Hyphenated language={de}>
                  <h1 style={{ color: this.props.content.fieldFarbe }}>
                    {this.props.content.fieldHeading}
                  </h1>
                </Hyphenated>
              ) : (
                <h1
                  style={{ color: this.props.content.fieldFarbe }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldHeading,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphPageTitle.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphPageTitle;
