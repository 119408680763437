import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphKontakt extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-kontakt": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <div className="row">
                {this.props.content.fieldPersonen.map((item, index) => (
                  <div
                    key={index}
                    className={classNames({
                      "col-16": true,
                      "col-lg-6": this.props.content.fieldBilderZeigen,
                      "offset-lg-1":
                        this.props.content.fieldBilderZeigen && index === 0,
                      "offset-lg-2": index !== 0,
                      "col-lg-3": !this.props.content.fieldBilderZeigen,
                      "offset-lg-4":
                        !this.props.content.fieldBilderZeigen && index === 0,
                    })}
                  >
                    <ErrorBoundary>
                      <TeaserBase
                        showImage={this.props.content.fieldBilderZeigen}
                        item={item.entity}
                        viewMode={"kontakt"}
                      />
                    </ErrorBoundary>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphKontakt.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphKontakt;
