import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import ExecutionEnvironment from "exenv";
import { Redirect, Link } from "react-router-dom";

import mySubmissionsQuery from "./my-submissions.graphql";
import SoundCitySubUsersQuery from "./sub-users.graphql";
import ParagraphSectionheading from "../../general-components/content-base/paragraphs/paragraph-sectionheading";
import LoadingIndicator from "../../general-components/loading-indicator";

const webFormIdsToShow = ["sound_city_2023"];
const SoundCitySubmissionsPage = () => {
  const [subUserIds, setSubUserIds] = useState([]);
  const logoutToken = useSelector((state) =>
    state.auth.userLoginData.logout_token
      ? state.auth.userLoginData.logout_token
      : null
  );
  const uid = useSelector((state) =>
    state.auth.userLoginData.logout_token
      ? state.auth.userLoginData.current_user.uid
      : null
  );

  useQuery(SoundCitySubUsersQuery, {
    variables: {
      uid: uid,
    },
    skip: !uid,
    onCompleted: (data) =>
      setSubUserIds(
        data.userById.fieldScAngemeldeteNutzer.map((item) => item.targetId)
      ),
  });

  // Get submissions (own and from sub-users via field__sc_angemeldete_nutzer).
  let {
    loading: mySubmissionsLoading,
    error: mySubmissionsError,
    data: mySubmissionsData,
  } = useQuery(mySubmissionsQuery, {
    variables: {
      uid: [uid, ...subUserIds],
    },
    skip: !uid,
    fetchPolicy: "network-only",
  });

  if (!logoutToken && ExecutionEnvironment.canUseDOM) {
    return <Redirect to="/intern/login" />;
  }
  console.log(mySubmissionsData);
  return (
    <div style={{ paddingBottom: "5rem" }}>
      <header>
        <ParagraphSectionheading content={{ fieldHeading: "Eingaben" }} />
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-15 col-lg-8 offset-xl-1">
              <h3>Meine Eingaben</h3>
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="row justify-content-between">
          <div className="col-15 col-lg-8 offset-xl-1">
            {mySubmissionsLoading ? <LoadingIndicator /> : null}

            {!mySubmissionsLoading &&
            mySubmissionsData &&
            mySubmissionsData.webformSubmissionQuery ? (
              <>
                {mySubmissionsData.webformSubmissionQuery.entities.map(
                  (item, index) => {
                    if (
                      webFormIdsToShow.includes(item.webformId.targetId) &&
                      item.uid.targetId == uid
                    ) {
                      const data = JSON.parse(item.data);

                      return (
                        <div key={index}>
                          <Link
                            to={`/intern/webform/${item.webformId.targetId}/${item.id}`}
                          >
                            {item.entityLabel} - {data.name_des_ensembles}
                          </Link>
                        </div>
                      );
                    }
                  }
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>

      {!mySubmissionsLoading &&
      mySubmissionsData &&
      mySubmissionsData.webformSubmissionQuery ? (
        <>
          {mySubmissionsData.webformSubmissionQuery.entities.filter(
            (item) =>
              webFormIdsToShow.includes(item.webformId.targetId) &&
              item.uid.targetId != uid
          ).length > 0 ? (
            <>
              <header style={{ marginTop: "3.5rem" }}>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-15 col-lg-8 offset-xl-1">
                      <h3>Eingaben der von mir angelegten Personen</h3>
                    </div>
                  </div>
                </div>
              </header>

              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-15 col-lg-8 offset-xl-1">
                    <>
                      {mySubmissionsData.webformSubmissionQuery.entities.map(
                        (item, index) => {
                          if (
                            webFormIdsToShow.includes(
                              item.webformId.targetId
                            ) &&
                            item.uid.targetId != uid
                          ) {
                            const data = JSON.parse(item.data);

                            return (
                              <div key={index}>
                                <Link
                                  to={`/intern/webform/${item.webformId.targetId}/${item.id}`}
                                >
                                  {item.entityLabel} - {data.name_des_ensembles}
                                </Link>
                              </div>
                            );
                          }
                        }
                      )}
                    </>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default SoundCitySubmissionsPage;
