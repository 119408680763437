import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {connect} from "react-redux";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import VisibilitySensor from 'react-visibility-sensor';

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite
});

class ParagraphNumberFacts extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-number-facts": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            {this.props.microSite ? (
              <VisibilitySensor resizeDelay={350} partialVisibility={true} minTopValue={350}>
                {({ isVisible }) => (
                  <div className={`fact-wrapper ${isVisible ? 'visible' : ''}`}>
                    {this.props.content.fieldZahlenUndFakten.length > 1 && (
                      <div className="col col-md-8 small">
                        {this.props.content.fieldZahlenUndFakten
                          .slice(0, this.props.content.fieldZahlenUndFakten.length)
                          .map((item, index) => (
                            <div
                              className="number-fact d-table d-md-block"
                              key={index}
                            >
                              <div className="wrapper">
                                <ParallaxProvider>
                                  <>
                                    <Parallax translateY={[-20, 20]}>
                                      <div className={`number ${item.entity.fieldZahl.length < 4 ? 'big' : ''} ${item.entity.fieldZahl.length > 4 && item.entity.fieldZahl.length < 8 ? 'medium' : ''}`} dangerouslySetInnerHTML={{
                                        __html: item.entity.fieldZahl,
                                      }}/>
                                    </Parallax>
                                    <div className="label">{item.entity.fieldLabel}</div>
                                  </>
                                </ParallaxProvider>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </VisibilitySensor>
            ) : (
              <>
                <div className="col d-flex align-items-center justify-content-center">
                  <div className="number-fact main">
                    <div className="number" dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldZahlenUndFakten[0].entity.fieldZahl,
                    }}/>
                    <div className="label">
                      {this.props.content.fieldZahlenUndFakten[0].entity.fieldLabel}
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none w-100" />
                {this.props.content.fieldZahlenUndFakten.length > 1 && (
                  <>
                    <div className="col col-md-8 small">
                      {this.props.content.fieldZahlenUndFakten
                        .slice(1, this.props.content.fieldZahlenUndFakten.length)
                        .map((item, index) => (
                          <div
                            className="number-fact d-table d-md-block"
                            key={index}
                          >
                            <div className="wrapper">
                              <div className="number">{item.entity.fieldZahl}</div>
                              <div className="label">{item.entity.fieldLabel}</div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="d-block d-md-none w-100" />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphNumberFacts.propTypes = {
  content: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ParagraphNumberFacts);