import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CustomCursor from "./custom-cursor";
import VisibilitySensor from "react-visibility-sensor";

let timeOutText;

const mapStateToProps = reduxStore => ({
  isMobile: reduxStore.appStore.isMobile
});

class ParagraphTextAndSpeech extends Component {

  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.textContainer = React.createRef();
  }

  mouseRef = React.createRef();

  state = {
    initialText: this.props.content.fieldText.value.replace(/<[^>]*>?/gm, ''),
    replaceText: '',
    stop: true,
    hover: false
  }

  textTransform = () => {

    let initialText = this.state.initialText,
      textForLoop = this.state.initialText,
      replaceText = this.state.replaceText,
      duration = this.player.duration * 1000 / textForLoop.length;

    for (let i = 0; i < textForLoop.length; i++) {

      if (this.state.stop) {
        break;
      }

      timeOutText = setTimeout(() => {
        replaceText = `${replaceText}${textForLoop[i]}`;
        initialText = initialText.slice(1);

        this.setState({
          initialText,
          replaceText
        });

        if (i === textForLoop.length - 1 || this.state.stop) {
          this.setState({
            initialText: this.props.content.fieldText.value.replace(/<[^>]*>?/gm, ''),
            replaceText: ''
          });
        }

      }, i * duration);
    }
  }

  toggle = () => {

    if (this.player.paused) {
      this.setState({
        stop: false
      }, () => {
        this.player.play();
        this.textTransform();
      });
    } else {
      this.setState({
        stop: true,
        initialText: this.props.content.fieldText.value.replace(/<[^>]*>?/gm, ''),
        replaceText: ''
      }, () => {
        this.player.pause();
        this.player.currentTime = 0;
        while (timeOutText--) {
          window.clearTimeout(timeOutText);
        }
      });
    }
  }

  changeHover = () => {
    this.setState({ stop: true });
  }


  hoverHandler = () => {
    this.setState({ hover: !this.state.hover });
  }

  render() {
    const { content } = this.props;

    if (this.props.isMobile) {
      return (
        <VisibilitySensor resizeDelay={250} partialVisibility={false}>
          {({ isVisible }) => (
            <div className="inner-wrapper">
              <div className={`paragraph paragraph-text-and-speech paragraph-alternative ${isVisible ? 'in-view' : ''} ${this.state.stop ? 'stopped' : 'playing'}`}>
                <div className="container">
                  <div className="row">
                    <div className={"animation-wrapper"}>
                      {this.state.stop ? (
                        <img className="play icon mobile" src="/sound-on.svg" alt="listen"/>
                      ) : (
                        <img className="pause icon mobile" src="/sound-off.svg" alt="listen"/>
                      )}
                    </div>
                    <div ref={this.textContainer} onClick={this.toggle} className={`${this.props.content.fieldQuelle ? 'offset-lg-2 col-lg-8' : 'offset-lg-1 col-lg-10'} text-container ${this.state.hover ? 'hover-cursor' : ''} ${!this.state.stop ? 'playing' : ''}`}>
                      <span className="text-wrapper">
                        {this.state.replaceText}
                        <span className="initial-text">
                          {this.state.initialText}
                        </span>
                      </span>
                      {this.props.content.fieldQuelle &&
                        <div className="source">
                          {this.props.content.fieldQuelle}
                        </div>
                      }
                    </div>
                    <audio ref={ref => this.player = ref} onEnded={() => this.changeHover()} src={content.fieldAudio.entity.fieldMediaAudioFile.entity.url}/>
                  </div>
                </div>
              </div>
            </div>
          )}
        </VisibilitySensor>
      );
    } else {
      return (
        <div className={`paragraph paragraph-text-and-speech paragraph-alternative ${this.state.stop ? 'stopped' : 'playing'}`} ref={this.mouseRef}>
          <div className="inner-wrapper">
            {this.state.hover &&
              <CustomCursor playing={this.state.stop} customRef={this.mouseRef.current}/>
            }
            <div className="container">
              <div className="row">
                <div ref={this.textContainer} onMouseEnter={this.hoverHandler} onMouseLeave={this.hoverHandler} onClick={this.toggle} className={`${this.props.content.fieldQuelle ? 'offset-lg-2 col-lg-8' : 'offset-lg-1 col-lg-10'} text-container ${this.state.hover ? 'hover-cursor' : ''} ${!this.state.stop ? 'playing' : ''}`}>
              <span className="text-wrapper">
                {this.state.replaceText}
                <span className="initial-text">
                  {this.state.initialText}
                </span>
              </span>
                  {this.props.content.fieldQuelle &&
                    <div className="source">
                      {this.props.content.fieldQuelle}
                    </div>
                  }
                </div>
                <audio ref={ref => this.player = ref} onEnded={() => this.changeHover()} src={content.fieldAudio.entity.fieldMediaAudioFile.entity.url}/>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

ParagraphTextAndSpeech.propTypes = {
  content: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ParagraphTextAndSpeech);