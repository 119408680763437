import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { encodeUrl } from "../../../../lib/encode-url";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ErrorBoundary from '../../../../error-boundary';
import ContentBase from "../../content-base";
import {openTreeAction} from "../../../../app-actions";
import {self} from "../../../../config";

class ParagraphKlappmodul extends Component {

  constructor(props) {
    super(props);

    let preExpandedItems = null;

    this.props.content.fieldKlappelemente.map((item, index) => {
      if (item.entity && encodeUrl(item.entity.fieldTitel) === this.props.location.hash.replace('#', '')) {
        preExpandedItems = index;
      }
    });

    this.state = { preExpandedItems };
  }

  clickElement = (element, title) => {
    let scrollPosition = element.getBoundingClientRect().top + window.scrollY - 150;

    history.pushState(null, window.location.pathname, `#${encodeUrl(title)}`);

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    if (this.state.preExpandedItems !== null) {
      this.props.content.fieldKlappelemente.map((item) => {
        if (item.entity && encodeUrl(item.entity.fieldTitel) === this.props.location.hash.replace('#', '') && document.getElementById(encodeUrl(item.entity.fieldTitel))) {
          setTimeout(() => {
            this.clickElement(document.getElementById(encodeUrl(item.entity.fieldTitel)), item.entity.fieldTitel);
          }, 500);
        }
      });
    }
  }


  render() {
    const sectionClassNames = classNames({
      'paragraph paragraph-akkordion-text': true
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldTitel &&
          <div className="wide-heading">
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <div className="title-wrapper">
                    <h2>{this.props.content.fieldTitel}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="container">
          <div className="row">
            <div className="col-16">
              <React.Suspense fallback={<div/>}>
                <Accordion  allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={[this.state.preExpandedItems]}>
                  {this.props.content.fieldKlappelemente.map((item, index) =>
                    <ErrorBoundary key={index}>
                      <AccordionItem
                        uuid={index}
                        id={encodeUrl(item.entity.fieldTitel).toString()}
                        key={encodeUrl(item.entity.fieldTitel)}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div onClick={(e) => this.clickElement(e.currentTarget, item.entity.fieldTitel)}>
                              <span>{item.entity.fieldTitel}</span>
                              <img className="arrow" alt={"klappelement button"} src={`${self}/sound-city/ui/chevron/down-tertiary.svg`}/>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ContentBase content={item.entity.fieldModule} nodeContent={item.entity} />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </ErrorBoundary>
                  )}
                </Accordion>
              </React.Suspense>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

ParagraphKlappmodul.propTypes = {
  content: PropTypes.object.isRequired
};

export default ParagraphKlappmodul;