import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ExternalInternalLink = (props) => (
  <>
    {props.link.url.routed ? (
      <Link className={props.className} to={props.link.url.path}>
        {props.link.label}
      </Link>
    ) : (
      <a
        className={props.className}
        target="_blank"
        rel="noopener noreferrer"
        href={props.link.url.path}
      >
        {props.link.label}
      </a>
    )}
  </>
);

ExternalInternalLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.object.isRequired,
};

export default ExternalInternalLink;
