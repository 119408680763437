import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import axios from "axios";
import { parse } from "yaml";

import ErrorFocus from "./elements/error-focus";
import LoadingIndicator from "../../../../general-components/loading-indicator";
import webformQuery from "./webform.graphql";
import submitMutation from "./submitMutation.graphql";
import { restHostBackend } from "../../../../config";
import WebformElements from "./webform-elements";
import { Redirect } from "react-router-dom";

const mapStateToProps = (reduxStore) => ({ reduxStore });
const webformElementsNotProcess = [
  "webform_markup",
  "webform_actions",
  "hidden",
];

export const webformElementsCustomExcluded = ["planned", "planned_event"];

// @todo Add details and container.
const webformElementsContainer = [
  "webform_custom_composite",
  "webform_address_composite",
  "fieldset",
  "webform_flexbox",
  "container",
];
const webFormElementsNoLevel = [
  "fieldset",
  "webform_flexbox",
  "container",
  "details",
];

class ParagraphFormular extends Component {
  state = {
    submitResponse: null,
    token: "",
    defaultInitialValues: this.props.defaultInitialValues,
    redirectSubmission: false,
  };

  formContainer = React.createRef();

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  };

  componentDidMount() {
    axios
      .get(`${restHostBackend}/session/token`)
      .then((response) => {
        // Save Session Token to redux store.
        this.setState({
          token: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  submitForm = (values, setSubmitting, resetForm) => {
    const formData = {
      webform_id: this.props.content.fieldFormularAuswahl.targetId,
      ...values,
      sid: this.props.submissionId,
    };

    this.props.client
      .mutate({
        mutation: submitMutation,
        variables: {
          values: JSON.stringify(formData),
        },
      })
      .then((response) => {
        setSubmitting(false);

        this.setState({
          submitResponse: response.data.submitForm,
        });

        if (
          response.data.submitForm.submission &&
          response.data.submitForm.submission.data
        ) {
          this.setState({
            defaultInitialValues: JSON.parse(
              response.data.submitForm.submission.data
            ),
          });
        }

        // Success.
        if (response.data.submitForm.submission) {
          if (!this.props.defaultInitialValues) {
            resetForm();
          }

          this.formContainer.current.scrollIntoView(true);

          // @todo redirect to submission page if id is sound_city_2023.
          if (
            this.props.content.fieldFormularAuswahl.targetId ===
            "sound_city_2023"
          ) {
            setTimeout(() => {
              this.setState({ redirectSubmission: true });
            }, 2500);
          }
        }

        // Error.
        if (response.data.submitForm.errors.length > 0) {
          this.formContainer.current.scrollIntoView({ block: "start" });
        }
      });
  };

  /**
   * Used to generate the initialValues.
   *
   * @param item
   * @returns {*[]|string|{}[]|*}
   */
  getElements = (item) => {
    let subElements = item.elements;

    if (item.type === "webform_address_composite") {
      const compositeSettings = JSON.parse(item.compositeSettings);

      if (compositeSettings.default_value) {
        subElements = item.elements.map((addressElement) => {
          if (
            compositeSettings.default_value.hasOwnProperty(addressElement.id)
          ) {
            return {
              ...addressElement,
              defaultValue: [
                compositeSettings.default_value[addressElement.id],
              ],
            };
          }

          return addressElement;
        });
      }
    }

    if (webformElementsContainer.includes(item.type)) {
      // Composite or container elements.
      let containerElements = {};

      subElements.forEach((containerItem, index) => {
        // Do not process these element types.
        if (
          webformElementsNotProcess.includes(containerItem.type) ||
          webformElementsCustomExcluded.includes(containerItem.id)
        ) {
          return;
        }

        containerElements[containerItem.id] = this.getElements(containerItem);
      });

      return item.type === "webform_address_composite" ||
        webFormElementsNoLevel.includes(item.type)
        ? containerElements
        : [containerElements];
    } else {
      if (item.defaultValue && item.defaultValue.length) {
        return item.defaultValue[0];
      } else {
        return [
          "select",
          "webform_term_select",
          "webform_entity_select",
          "checkboxes",
        ].includes(item.type) && !!item.multiple
          ? []
          : "";
      }
    }
  };

  getValidationSchema = (item) => {
    let itemSchema;
    switch (item.type) {
      case "webform_custom_composite":
        itemSchema = yup.array();
        break;

      case "textfield":
      case "textarea":
        itemSchema = yup.string();

        if (item.maxLength) {
          itemSchema.max(item.maxLength);
        }
        if (item.minLength) {
          itemSchema.min(item.minLength);
        }

        break;

      // @todo Make date more robust.
      case "date":
        itemSchema = yup.string();
        break;

      case "url":
        itemSchema = yup.string().matches(
          // @see https://stackoverflow.com/a/68002755
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
          "Bitte geben Sie eine valide URL ein!"
        );
        break;

      case "email":
        itemSchema = yup
          .string()
          .email("Bitte geben Sie eine valide E-Mail Adresse ein!");
        break;

      case "number":
        itemSchema = yup.number();
        break;

      case "managed_file":
        itemSchema = yup.string();
        break;

      case "radios":
        itemSchema = yup.string();
        break;

      case "checkbox":
        itemSchema = yup.boolean();
        break;

      case "checkboxes":
        itemSchema = yup.array();
        break;

      // @todo Checkboxes limit not working in graphql_webform.
      //case 'checkboxes':
      case "select":
      case "webform_entity_select":
      case "webform_term_select":
        itemSchema = yup.array();

        if (!!item.multiple && item.multiple.limit && item.multiple.message) {
          itemSchema = itemSchema.max(
            item.multiple.limit,
            item.multiple.message
              ? item.multiple.message
              : `Maximal ${item.multiple.limit} Werte sind zulässig!`
          );
        }

        if (item.multiple === null) {
          itemSchema = yup.string();
        }

        break;
    }

    // Mark as not required if there are 'visible' states, in that case
    // the logic is handled via the required prop on the field...
    // ...which does not work, so the mutations returns an error which
    // is shown to the user.
    if (!!item.required && item.states === null) {
      const requiredMessage = item.required.message
        ? item.required.message
        : `${item.title} ist ein Pflichtfeld!`;

      if (item.type === "checkbox") {
        itemSchema = itemSchema.oneOf([true], requiredMessage);
      } else {
        itemSchema = itemSchema.required(requiredMessage);
      }
    }

    return itemSchema;
  };

  render() {
    if (this.props.data.loading) {
      return <LoadingIndicator />;
    }

    if (this.props.data.error) {
      return <div>{this.props.data.error.message}</div>;
    }

    if (this.state.redirectSubmission) {
      return <Redirect to={"/intern/sound-city/eingaben"} />;
    }

    let initialValues = {},
      validationSchema = {};

    this.props.data.webformById.elements.forEach((item) => {
      // Do not process these element types.
      if (
        webformElementsNotProcess.includes(item.type) ||
        webformElementsCustomExcluded.includes(item.id)
      ) {
        return;
      }

      // Get initial values.
      if (webFormElementsNoLevel.includes(item.type)) {
        // For these elements, the subelements are not nested into their parents.
        item.elements.forEach((rootItem) => {
          if (webFormElementsNoLevel.includes(rootItem.type)) {
            initialValues = {
              ...initialValues,
              ...this.getElements(rootItem),
            };
          } else {
            initialValues = {
              ...initialValues,
              [rootItem.id]: this.getElements(rootItem),
            };
          }
        });
      } else {
        initialValues = {
          ...initialValues,
          [item.id]: this.getElements(item),
        };
      }

      // Validation schema.
      if (webFormElementsNoLevel.includes(item.type)) {
        item.elements.forEach((rootItem) => {
          validationSchema = {
            [rootItem.id]: this.getValidationSchema(rootItem),
            ...validationSchema,
          };
        });
      } else {
        validationSchema = {
          [item.id]: this.getValidationSchema(item),
          ...validationSchema,
        };
      }
    });

    const formParagraphDefaultData = this.props.content.fieldFormularAuswahl
      .defaultData
      ? parse(this.props.content.fieldFormularAuswahl.defaultData)
      : {};

    return (
      <div className={`paragraph webform-paragraph`}>
        <Formik
          initialValues={
            this.state.defaultInitialValues
              ? {
                  ...initialValues,
                  ...formParagraphDefaultData,
                  ...this.state.defaultInitialValues,
                }
              : { ...initialValues, ...formParagraphDefaultData }
          }
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            this.submitForm(values, setSubmitting, resetForm)
          }
        >
          {(formik) => (
            <div
              className={`container webform-container`}
              ref={this.formContainer}
              style={{ scrollMarginTop: "175px" }}
            >
              <div className="row">
                <div className={`col-16 offset-lg-2 col-lg-12`}>
                  {/* Error on submit from CS */}
                  {this.state.submitResponse &&
                    !this.state.submitResponse.submission &&
                    this.state.submitResponse.errors.length && (
                      <div
                        className={`${this.props.content.fieldFormularAuswahl.targetId} form-messages`}
                      >
                        <div className="alert alert-danger" role="alert">
                          {this.state.submitResponse.errors.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                <span>{item}</span>
                                <br />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    )}

                  {/* Formik validation errors */}
                  {formik.submitCount > 0 && !formik.isValid && (
                    <div
                      className={`${this.props.content.fieldFormularAuswahl.targetId} form-messages`}
                    >
                      <div className="alert alert-danger" role="alert">
                        <b>
                          Achtung! Das Ausfüllen der Pflichtfelder (mit *
                          markiert) ist zwingend notwendig zum
                          Zwischenspeichern!
                        </b>
                      </div>
                    </div>
                  )}

                  {this.state.submitResponse &&
                    this.state.submitResponse.submission &&
                    !this.state.submitResponse.errors.length && (
                      <div
                        className={`${this.props.content.fieldFormularAuswahl.targetId} form-messages`}
                      >
                        <div className="alert alert-success" role="alert">
                          <>Formulareingabe erfolgreich gespeichert.</>
                        </div>
                      </div>
                    )}

                  <Form
                    noValidate
                    className={this.props.content.fieldFormularAuswahl.targetId}
                  >
                    <WebformElements
                      formik={formik}
                      items={this.props.data.webformById.elements}
                      token={this.state.token}
                      generatedInitialValues={initialValues}
                    />

                    <ErrorFocus
                      formRef={this.formContainer.current}
                      isSubmitting={formik.isSubmitting}
                      errors={formik.errors}
                      isValidating={formik.isValidating}
                    />

                    {formik.isSubmitting && <LoadingIndicator />}
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

ParagraphFormular.propTypes = {
  client: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  defaultInitialValues: PropTypes.object,
  submissionQuery: PropTypes.object,
  submissionId: PropTypes.string,
};

export default connect(mapStateToProps)(
  graphql(webformQuery, {
    options: (props) => ({
      variables: { id: props.content.fieldFormularAuswahl.targetId },
    }),
  })(withApollo(ParagraphFormular))
);
