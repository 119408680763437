import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const loadDynamicScript = (callback) => {
  const existingScript = document.getElementById("scriptId");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://static.b-ite.com/jobs-api/loader-v1/api-loader-v1.min.js";
    script.id = "biteJobs";
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

class ParagraphIframeEmbed extends Component {
  componentDidMount() {
    loadDynamicScript();
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-iframe-embed": true,
    });

    return (
      <section className={sectionClassNames}>
        <div
          className="text iframe"
          dangerouslySetInnerHTML={{
            __html: this.props.content.fieldIframeContent,
          }}
        />
      </section>
    );
  }
}

ParagraphIframeEmbed.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphIframeEmbed;
