import React, { Component } from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";

export const YouTubeVideo = (props) => {
  return (
    <section className={props.sectionClassNames}>
      {props.content.fieldGroesse === "0" && (
        <div className="full-width">
          <div className="iframe-wrap iframe-16-9">
            <YouTube videoId={props.content.fieldYoutubeVideoUrl.videoId} />
          </div>
        </div>
      )}
      {props.content.fieldGroesse === "1" && (
        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-8 offset-lg-4 video-wrapper">
              <div className="iframe-wrap iframe-16-9">
                <YouTube videoId={props.content.fieldYoutubeVideoUrl.videoId} />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

YouTubeVideo.propTypes = {
  content: PropTypes.object.isRequired,
  sectionClassNames: PropTypes.string,
};
