import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import { self } from "./../../config";
import ParagraphFormular from "../../general-components/content-base/paragraphs/webform/paragraph-formular";
import webformSubmissionQuery from "./webform-submission.graphql";
import ParagraphSectionheading from "../../general-components/content-base/paragraphs/paragraph-sectionheading";
import ParagraphLogosPartners from "../../general-components/content-base/paragraphs/logos-partners/logos-partners";

const WebformPage = (props) => {
  const [submissionData, setSubmissionData] = useState(null);
  const { loading, error, data } = useQuery(webformSubmissionQuery, {
    variables: {
      sid: props.match.params.submissionId,
    },
    skip: !props.match.params.submissionId,
    onCompleted: (data) =>
      setSubmissionData(JSON.parse(data.webformSubmissionById.data)),
  });

  if (loading) {
    return null;
  }

  return (
    <div>
      <ParagraphSectionheading
        content={{
          fieldHeading: props.match.params.webformId.replace(/_/g, " "),
        }}
      />
      <ParagraphFormular
        content={{
          fieldFormularAuswahl: { targetId: props.match.params.webformId },
        }}
        defaultInitialValues={submissionData}
        submissionId={props.match.params.submissionId}
      />
      <ParagraphLogosPartners
        content={{
          fieldHeading: "Das Projekt wird gefördert durch:",
          fieldLogosPartners: [
            {
              entity: {
                fieldLink: null,
                fieldMediaImage1: {
                  alt: "EFRE Logo",
                  title: "EFRE Logo",
                  style: {
                    url: `${self}/efre_logo_react-eu_mitrand_s.png`,
                  },
                },
              },
            },
          ],
        }}
      />
    </div>
  );
};

WebformPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default WebformPage;
