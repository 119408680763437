import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import classNames from "classnames";
import { states } from "../states";
import WebformElements from "../webform-elements";
const Flexbox = ({ formik, item, token, compositeIndex, compositeParent }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  return (
    <div
      className={classNames({
        "form-group webform-flexbox": true,
        hidden: invisible || !visible,
      })}
      style={
        item.flex
          ? { flex: item.flex, alignItems: item.alignItems }
          : { alignItems: item.alignItems }
      }
    >
      <WebformElements
        formik={formik}
        items={item.elements}
        token={token}
        compositeParent={compositeParent}
        compositeIndex={compositeIndex}
      />

      <ErrorMessage component="span" name={item.id} className="error-message" />

      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

Flexbox.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default Flexbox;
