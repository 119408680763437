import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../image";
import ErrorBoundary from "../../../error-boundary";

class ParagraphZitat extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-zitat": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            {this.props.content.fieldImage !== null && (
              <div className="col-2 offset-md-2">
                <ErrorBoundary>
                  <Image
                    data={this.props.content.fieldImage.entity.fieldMediaImage}
                  />
                </ErrorBoundary>
              </div>
            )}

            <div
              className={classNames({
                "col-10": this.props.content.fieldImage !== null,
                "col-12 offset-md-2": this.props.content.fieldImage === null,
              })}
            >
              <blockquote className="text">
                {this.props.content.fieldZitat}
                <cite>{this.props.content.fieldQuelle}</cite>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphZitat.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphZitat;
