import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../backend/edit-button";

import Image from "../image";
import { pagerFullPageContextAction } from "../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite,
});

class TeaserPerson extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-person">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <article
          /*onClick={() => this.props.dispatch(pagerFullPageContextAction(this.props.pagerFullPage))}*/
          className="flex-wrap"
          /*to={this.props.item.path.alias}*/
        >
          <Image
            data={this.props.item.fieldBildWCaption.entity.fieldMediaImage}
            nodeTitle={this.props.item.title}
            scCustomStyle={this.props.microSite !== false}
          />
          <h3>{this.props.item.title}</h3>
          {this.props.item.fieldSchlagwort.length > 0 && (
            <span className="top-line">
              {this.props.item.fieldSchlagwort[0].entity.name}
            </span>
          )}
          <div className="contact">
            {Boolean(this.props.item.fieldMail) && (
              <span className="mail">
                Mail:&nbsp;
                <a href={`mailto:${this.props.item.fieldMail}`}>
                  {this.props.item.fieldMail}
                </a>
              </span>
            )}
            {Boolean(this.props.item.fieldTelefon) && (
              <span className="phone">{`Telefon: ${this.props.item.fieldTelefon}`}</span>
            )}
            {Boolean(this.props.item.fieldMobil) && (
              <span className="mobil">{`Mobil: ${this.props.item.fieldMobil}`}</span>
            )}
          </div>
          {Boolean(this.props.item.fieldBeschreibung) && (
            <div
              className="info"
              dangerouslySetInnerHTML={{
                __html: this.props.item.fieldBeschreibung.value,
              }}
            />
          )}
        </article>
      </article>
    );
  }
}

TeaserPerson.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPerson));
