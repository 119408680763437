import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import {graphql} from "@apollo/client/react/hoc";
import teaserNodeQueryEventDateFiltered
  from "../../../../general-components/teaser-queries/teaser-node-query-event-date-filtered.graphql";
import Programm from "./programm";
import moment from "moment";
import LoadingIndicator from "../../../../general-components/loading-indicator";

class ParagraphProgramm extends Component {

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-programm": true
    });

    return (
      <section className={sectionClassNames}>
        <div className="container sticky">
          <div className="row justify-content-between">
            <div className="col-15 col-lg-12 col-xl-10">
              <Hyphenated language={de}>
                <h1 className="heading">
                  {this.props.content.fieldHeading}
                </h1>
              </Hyphenated>
            </div>
          </div>
        </div>
        <div className="programm-wrapper">
          { this.props.nodes.nodeQuery && this.props.nodes.nodeQuery.entities  ? (
            <Programm content={this.props.nodes.nodeQuery.entities} download={this.props.content.fieldProgrammheft}/>
            ) : (
            <LoadingIndicator fullPage={true} backgroundFilled={true} />
          )}
        </div>
      </section>
    );
  }
}

ParagraphProgramm.propTypes = {
  content: PropTypes.object.isRequired,
};

export default graphql(teaserNodeQueryEventDateFiltered, {
  name: "nodes",
  options: (props) => {
    return {
      variables: {
        type: "veranstaltung",
        end_date: moment(props.content.fieldZeitraum.endValue).endOf('day'),
        date: props.content.fieldZeitraum.value,
        filterKanal: true,
        kanal: props.content.fieldKanalTaxonomie ? props.content.fieldKanalTaxonomie.entity.entityId : null
      },
    };
  },
})(ParagraphProgramm);
