import React, { useState } from "react";
import classNames from "classnames";
import Image from "../../../image";
import {ErrorBoundary} from "carbon-components-react";
import ReactPaginate from "react-paginate";
import Lightbox from "lightbox-react";
import {useSelector} from "react-redux";

const ParagraphImageCollection = (props) => {

  const reduxStore = useSelector((state) => ({
    microSite: state.appStore.microSite
  }));

  const getItemsToShow = (items, start, end) => {
    return items.slice(start, end);
  };

  const [itemsMatching, setItemsMatching] = useState(props.content.fieldBilder);
  const [itemsToShow, setItemsToShow] = useState(getItemsToShow(itemsMatching, 0, 12));
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [paginationIndex, setPaginationIndex] = useState(0);

  const paginationOffset = paginationIndex * 12;

  // Change Pagination
  const handlePageClick = (data) => {
    setPaginationIndex(data.selected);
    setItemsToShow(
      getItemsToShow(
      itemsMatching,
      data.selected * 12,
      (data.selected * 12) + 12
    ));
  }

  // Toggle Lightbox and set index to clicked image
  const toggleLightbox = (index) => {
    setLightboxOpen(!lightboxOpen);
    setPhotoIndex(index);
  }

  const colClasses = classNames({
    "col-16 col-lg-12 offset-lg-2": reduxStore.microSite !== "sound-city",
    "col-16": reduxStore.microSite === "sound-city"
  });


  return (
    <section
      className={classNames({
        "paragraph paragraph-image-collection": true
      })}
    >
      <div className="container">
        <div className="row">
          <div className={colClasses}>
            <div className="row">
              {itemsToShow?.map((item, index) =>
                <div className="col-8 col-md-4 image-wrapper" key={index} onClick={() => toggleLightbox(index)}>
                  <Image
                    data={item.entity.fieldMediaImage}
                  />
                </div>
              )}
            </div>
            {itemsToShow && itemsMatching?.length > 12 &&
              <div className="pagination">
                <ErrorBoundary>
                  <ReactPaginate
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(itemsMatching.length / 12)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    previousLabel={'zurück'}
                    nextLabel={'weiter'}
                    previousClassName="pagination previous"
                    nextClassName="pagination next"
                  />
                </ErrorBoundary>
              </div>
            }
            <ErrorBoundary>
              {lightboxOpen && (
                <div className="container lightbox-overlay">
                  <div className="row">
                    <div className="col-16">
                      <Lightbox
                        mainSrc={
                          <Image
                            data={itemsMatching[paginationOffset + photoIndex].entity.fieldMediaImage}
                            caption={itemsMatching[paginationOffset + photoIndex].entity.fieldBildunterschrift}
                            credit={itemsMatching[paginationOffset + photoIndex].entity.fieldCredit}
                            fullPage={true}
                          />
                        }

                        nextSrc={
                          <>
                            {itemsMatching[paginationOffset + photoIndex + 1] &&
                              <Image
                                data={itemsMatching[paginationOffset + photoIndex + 1].entity.fieldMediaImage}
                                caption={itemsMatching[paginationOffset + photoIndex + 1].entity.fieldBildunterschrift}
                                credit={itemsMatching[paginationOffset + photoIndex + 1].entity.fieldCredit}
                                fullPage={true}
                              />
                            }
                          </>
                        }

                        prevSrc={
                        <>
                          {photoIndex > 1 &&
                            <Image
                              data={itemsMatching[(photoIndex - 1) % itemsMatching.length].entity.fieldMediaImage}
                              caption={itemsMatching[photoIndex - 1].entity.fieldBildunterschrift}
                              credit={itemsMatching[photoIndex - 1].entity.fieldCredit}
                              fullPage={true}
                            />
                          }
                        </>
                        }
                        onCloseRequest={() => toggleLightbox()}
                        clickOutsideToClose={true}
                        enableZoom={false}
                        discourageDownloads={true}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + itemsToShow.length - 1) % itemsToShow.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % itemsToShow.length)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParagraphImageCollection;