import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

/**
 * TODO: fieldSetAnchorLink.
 */
class ParagraphSectionheading extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-15 col-lg-8 offset-xl-1">
              <Hyphenated language={de}>
                <h2
                  id={this.props.content.fieldHeading
                    .toLowerCase()
                    .replace(/\W/g, "-")}
                  className="section-heading"
                >
                  {this.props.content.fieldHeading}
                </h2>
              </Hyphenated>
            </div>
            {this.props.content.fieldSetAnchorLink && (
              <div className="col-1">
                <a
                  className="anchor"
                  href={`#${this.props.content.fieldHeading
                    .toLowerCase()
                    .replace(/\W/g, "-")}`}
                >
                  Anker
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphSectionheading.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphSectionheading;
