import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import { states } from "../states";
import classNames from "classnames";

const CheckboxesField = ({ formik, item }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      {item.options.map((el) => {
        return (
          <div key={`${item.id}_${el.value}`} className="form-check">
            <Field
              className="form-check-input"
              type="checkbox"
              name={item.id}
              id={el.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={el.value}
              required={(!!item.required || required) && !optional && visible}
              disabled={!enabled || disabled}
            />
            <label className="form-check-label" htmlFor={el.value}>
              {el.title}
            </label>
          </div>
        );
      })}
      <ErrorMessage component="span" name={item.id} className="error-message" />
      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

CheckboxesField.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default CheckboxesField;
