import Slider from "react-slick";
import React, { Component } from "react";
import PropTypes from "prop-types";

import Image from "./../../../image";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite
});

const SliderPrev = (props) => {
  return (
    <div
      className="left-arrow-wrap"
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onClick={props.sliderPrevious}
    >
      <div className="slick-arrow slick-prev">
        <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />
      </div>
    </div>
  );
};

const SliderNext = (props) => {
  return (
    <div
      className="right-arrow-wrap"
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onClick={props.sliderNext}
    >
      <div className="slick-arrow slick-next">
        <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />
      </div>
    </div>
  );
};

/**
 * TODO: Refactor mouse sticky buttons.
 */
class ParagraphMedia extends Component {
  slider = React.createRef();
  sliderContainer = React.createRef();

  onMouseMove = (e) => {
    const prev = this.sliderContainer.current.querySelector(".slick-prev"),
      next = this.sliderContainer.current.querySelector(".slick-next"),
      bounds = this.sliderContainer.current
        .querySelector(".hover-controls")
        .getBoundingClientRect();

    if (e.target.classList.contains("left-arrow-wrap")) {
      prev.style.top = `${e.clientY - bounds.top + 30}px`;
      prev.style.left = `${e.clientX - bounds.left}px`;
    } else if (e.target.classList.contains("right-arrow-wrap")) {
      next.style.top = `${e.clientY - bounds.top + 30}px`;
      next.style.right = `${(e.clientX - bounds.right) * -1}px`;
    }
  };

  onMouseLeave = () => {
    const prev = this.sliderContainer.current.querySelector(".slick-prev"),
      next = this.sliderContainer.current.querySelector(".slick-next");

    prev.style.removeProperty("left");
    prev.style.removeProperty("top");

    next.style.removeProperty("right");
    next.style.removeProperty("top");
  };

  sliderPrevious = () => {
    this.slider.current.slickPrev();
  };

  sliderNext = () => {
    this.slider.current.slickNext();
  };

  render() {
    return (
      <div ref={this.sliderContainer} className="gallery">
        <div className="hover-controls">
          <Slider ref={this.slider} {...this.props.sliderSettings}>
            {this.props.bilder.map((item, index) => (
              <div key={index} className="slide-article-wrap">
                <article className="media-image">
                  <Image
                    data={item.entity.fieldMediaImage}
                    credit={item.entity.fieldCredit}
                    caption={
                      item.defaultCaption === false ? item.customCaption : null
                    }
                    globalCaption={this.props.globalCaption}
                    wrapCaption={this.props.wrapCaption}
                  />
                </article>
              </div>
            ))}
          </Slider>
          {!this.props.microsite &&
            <>
              <SliderPrev
                onMouseMove={(e) => this.onMouseMove(e)}
                onMouseLeave={() => this.onMouseLeave()}
                sliderPrevious={() => this.sliderPrevious()}
              />
              <SliderNext
                onMouseMove={(e) => this.onMouseMove(e)}
                onMouseLeave={() => this.onMouseLeave()}
                sliderNext={() => this.sliderNext()}
              />
            </>
          }
        </div>
      </div>
    );
  }
}

ParagraphMedia.propTypes = {
  bilder: PropTypes.array.isRequired,
  globalCaption: PropTypes.string,
  sliderSettings: PropTypes.object.isRequired,
  wrapCaption: PropTypes.bool,
};

export default connect(mapStateToProps)(ParagraphMedia);
