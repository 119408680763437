import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import { connect } from "react-redux";
import { compose } from "recompose";

import teaserNodeQuery from "../../../../teaser-queries/teaser-node-query.graphql";
import teaserNodeQueryTagFiltered from "../../../../teaser-queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews from "../../../../teaser/teaser-news";
import TeaserPerson from "../../../../teaser/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import TeaserSocialPost from "../../../../teaser/teaser-social-post";

const sliderSettings = {
  adaptiveHeight: false,
  arrows: true,
  centerMode: false,
  dots: false,
  infinite: false,
  swipeToSlide: true,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  touchMove: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.66,
        touchMove: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1.66,
        touchMove: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        touchMove: false,
        swipeToSlide: false,
      },
    },
  ],
};

class ComponentTeaserlistListSlick extends React.Component {
  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  render() {
    return (
      <>
        {this.props.nodesConfig === "Manuell" ? (
          <Slider {...sliderSettings}>
            {this.props.manualNodes.map((item, index) => (
              <React.Fragment key={index}>
                {(() => {
                  switch (item.entity.entityBundle) {
                    case "news":
                      return (
                        <TeaserNews
                          item={item.entity}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    case "person":
                      return (
                        <TeaserPerson
                          item={item.entity}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </React.Fragment>
            ))}
          </Slider>
        ) : (
          <>
            {this.props.nodes.nodeQuery &&
            this.props.nodes.nodeQuery.entities.length > 0 ? (
              <>
                <Slider {...sliderSettings}>
                  {this.props.nodes.nodeQuery.entities.map((item, index) => (
                    <React.Fragment key={index}>
                      {(() => {
                        switch (item.entityBundle) {
                          case "news":
                            return (
                              <TeaserNews
                                item={item}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          case "social_post":
                            return (
                              <TeaserSocialPost
                                item={item}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          case "person":
                            return (
                              <TeaserPerson
                                item={item}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </React.Fragment>
                  ))}
                </Slider>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </>
        )}
      </>
    );
  }
}

ComponentTeaserlistListSlick.propTypes = {
  count: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  manualNodes: PropTypes.array,
  id: PropTypes.string.isRequired,
  nodes: PropTypes.object.isRequired,
  nodesConfig: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  pagerFullPageOverviewLink: PropTypes.object,
  tags: PropTypes.array.isRequired,
};

export default compose(
  graphql(teaserNodeQuery, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length > 0,
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "news" ? ["news", "social_post"] : [props.type],
      },
    }),
  }),
  graphql(teaserNodeQueryTagFiltered, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length === 0,
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "news" ? ["news", "social_post"] : [props.type],
        tags: props.tags.map((item) => item.targetId.toString()),
      },
    }),
  })
)(connect()(ComponentTeaserlistListSlick));

export { sliderSettings };
