import React, { Component } from "react";
import Loader from "react-loader-spinner";

class LoadingIndicator extends Component {
  render() {
    return (
      <div className={`loading-indicator-wrap ${this.props.fullPage ? 'full-page' : ''} ${this.props.backgroundFilled ? 'background-filled' : ''}`}>
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={50000}
        />
      </div>
    );
  }
}

export default LoadingIndicator;
