import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Image from "../../image";

class ParagraphTeaserImage extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-image": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div
            className={classNames({
              row: true,
              "flex-row-reverse":
                this.props.content.fieldImagePosition === "right",
            })}
          >
            <div
              className={classNames({
                "col-16 col-lg-8": true,
                "offset-lg-1":
                  this.props.content.fieldImagePosition === "right",
              })}
            >
              <Image
                data={this.props.content.fieldImage.entity.fieldMediaImage}
                nodeTitle={this.props.content.fieldHeading}
              />
            </div>
            <div
              className={classNames({
                "col 16 col-lg-7": true,
                "offset-lg-1": this.props.content.fieldImagePosition === "left",
              })}
            >
              <h3>{this.props.content.fieldHeading}</h3>
              {this.props.content.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldText.value,
                  }}
                />
              )}
              {this.props.content.fieldCallToAction && (
                <Link
                  className="btn btn-primary"
                  to={this.props.content.fieldCallToAction.url.path}
                >
                  {this.props.content.fieldCallToAction.title}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphTeaserImage.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphTeaserImage;
