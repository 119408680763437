import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PlayFilledAlt32, PauseFilled32, Rewind_1032, VolumeUp32, VolumeMute32, VolumeDown32 } from '@carbon/icons-react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Image from "../../image";

class ParagraphAudio extends Component {

  state = {
    currentTime: 0,
    duration: 0
  };

  timeUpdateListener = e => {
    this.setState({
      currentTime: e.target.currentTime,
      duration: e.target.duration
    });
  };

  constructor(props) {
    super(props);

    this.player = React.createRef();
  }

  getTime = (time) => {
    if (!isNaN(time)) {
      return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2) + ' min';
    }

    return null;
  };

  goBack = seconds => {
    this.player.currentTime = this.state.currentTime - seconds;
  }

  play = () => {
    this.player.play();
  };

  pause = () => {
    this.player.pause();
  };

  minusVolume = () => {
    this.player.volume = this.player.volume - 0.2;
  }

  plusVolume = () => {
    this.player.volume = this.player.volume + 0.2;
  }

  mute = () => {
    this.player.volume = 0.0;
  }

  componentDidMount() {
    this.player.addEventListener('timeupdate', e => this.timeUpdateListener(e));
    this.player.addEventListener('loadedmetadata', e => this.audioLoaded(e));

    this.setState({
      currentTime: this.player.currentTime,
      duration: this.player.duration
    });
  }

  componentWillUnmount() {
    this.player.removeEventListener('timeupdate', e => this.timeUpdateListener(e));
  }

  audioLoaded = () => {
    this.setState({
      currentTime: this.player.currentTime,
      duration: this.player.duration
    });
  }

  skipThrough = (e) => {
    // Calculate the difference from start to click of the bar, to get the wanted "percentage to skip to".
    let difference = (e.clientX - e.target.getBoundingClientRect().left) / e.target.parentElement.getBoundingClientRect().width;
    this.player.currentTime = difference * this.player.duration;
  }

  render() {
    const sectionClassNames = classNames({
        'paragraph paragraph-audio': true,
        'with-image': this.props.content && this.props.content.fieldImage,
        'without-image': this.props.content && !this.props.content.fieldImage
      }),
      { content } = this.props,
      currentTime = this.getTime(this.state.currentTime),
      duration = this.getTime(this.state.duration);

    return (
      <section className={sectionClassNames}>
        {content.fieldImage &&
          <div className="thumbnail">
            <Image data={content.fieldImage.entity.fieldMediaImage} />
          </div>
        }
        <div className="container">
          <div className="row">
            <div className="col-16 audio-section">
              <div className="text-wrapper">
                {Boolean(content.fieldTitleAudio) &&
                  <h2>{content.fieldTitleAudio}</h2>
                }
                {Boolean(content.fieldText) &&
                  <div className="info">
                    {content.fieldText &&
                      <div dangerouslySetInnerHTML={{ __html: content.fieldText.processed }} />
                    }
                  </div>
                }
              </div>
              <div className="time">
                <div className="current">
                  {currentTime ? (
                    <span className="current-time">
                      {currentTime}
                    </span>
                  ) : (
                    <span className="current-time">
                      00:00 min
                    </span>
                  )}
                  {duration && <React.Fragment>/ {duration}</React.Fragment>}
                </div>
              </div>
              <div className="audio">
                <audio
                  ref={ref => this.player = ref}
                  onCanPlay={() => this.audioLoaded()}
                >
                  <source src={content.fieldAudioDatei.entity.fieldMediaAudioFile.entity.url}/>
                </audio>
                <div className="button">
                  <div className="function">
                    {this.player.paused ? (
                      <button className="audio-toggle" onClick={this.play}>
                        <img src={'/play.svg'}/>
                      </button>
                    ) : (
                      <button className="audio-toggle" onClick={this.pause}>
                        <img src={'/pause.svg'}/>
                      </button>
                    )}
                  </div>
                </div>
                <div className="bar-wrapper">
                  <div className={`progress-bar ${this.state.currentTime === 0 ? 'paused' : 'playing'}`}>
                    <div className="bar" onClick={(e) => this.skipThrough(e)}>
                      <div className="fill" style={{
                        width: `${(100 / this.state.duration) * this.state.currentTime}%`
                      }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

ParagraphAudio.propTypes = {
  content: PropTypes.object.isRequired
};

export default ParagraphAudio;
