import React from "react";
import PropTypes from "prop-types";

import Image from "../../../image";
import ComponentSlider from "./component-slider";

const ComponentBildSlider = (props) => (
  <>
    {props.bilder.length > 1 ? (
      <ComponentSlider
        bilder={props.bilder}
        sliderSettings={props.sliderSettings}
        globalCaption={props.globalCaption}
        wrapCaption={props.wrapCaption}
      />
    ) : (
      <article className="media-image">
        <Image
          data={props.bilder[0].entity.fieldMediaImage}
          credit={props.bilder[0].entity.fieldCredit}
          caption={
            props.bilder[0].defaultCaption === false
              ? props.bilder[0].customCaption
              : null
          }
          globalCaption={props.globalCaption}
          wrapCaption={props.wrapCaption}
        />
      </article>
    )}
  </>
);

ComponentBildSlider.propTypes = {
  bilder: PropTypes.array.isRequired,
  globalCaption: PropTypes.string,
  sliderSettings: PropTypes.object.isRequired,
  wrapCaption: PropTypes.bool,
};

export default ComponentBildSlider;
