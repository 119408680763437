import React from "react";
import PropTypes from "prop-types";
import { Play24, Pause24 } from "@carbon/icons-react";
import classNames from "classnames";

class ComponentVideo extends React.Component {
  state = {
    videoPlaying: false,
  };

  videoSection = React.createRef();

  playPauseVideo = () => {
    const video = this.videoSection.current.querySelectorAll("video")[0];

    if (video.paused) {
      // Play the video
      video.play();

      // Update the button text to 'Pause'
      this.setState({ videoPlaying: true });
    } else {
      // Pause the video
      video.pause();

      // Update the button text to 'Play'
      this.setState({ videoPlaying: false });
    }
  };

  render() {
    return (
      <section
        className={classNames({
          [this.props.sectionClassNames]: true,
          "video-playing": this.state.videoPlaying,
        })}
        ref={this.videoSection}
      >
        {this.props.content.fieldGroesse === "0" && (
          <div className="full-width local-video">
            {Boolean(this.props.content.fieldVideoFileMedia) && (
              <>
                <video
                  src={
                    this.props.content.fieldVideoFileMedia.entity
                      .fieldMediaVideoFile.entity.url
                  }
                  controls={false}
                />
                <div
                  className="playpause"
                  onClick={() => this.playPauseVideo()}
                >
                  {this.state.videoPlaying ? (
                    <span>Pause</span>
                  ) : (
                    <span>Play</span>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {this.props.content.fieldGroesse === "1" && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-lg-8 offset-lg-4 video-wrapper local-video">
                {Boolean(this.props.content.fieldVideoFileMedia) && (
                  <>
                    <video
                      src={
                        this.props.content.fieldVideoFileMedia.entity
                          .fieldMediaVideoFile.entity.url
                      }
                      controls={false}
                    />
                    <div
                      className="playpause"
                      onClick={() => this.playPauseVideo()}
                    >
                      {this.state.videoPlaying ? (
                        <span>Pause</span>
                      ) : (
                        <span>Play</span>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ComponentVideo.propTypes = {
  content: PropTypes.object.isRequired,
  sectionClassNames: PropTypes.string,
};

export default ComponentVideo;
