import React, { useState, useEffect } from "react";
import classNames from "classnames";

import ErrorBoundary from "../../error-boundary";
import ComponentClickSuggestionLink from "../../general-components/content-base/paragraphs/component-click-suggestion-link";

const CTATeaser = ({ content }) => {
  return (
    <section
      className="paragraph paragraph-call-to-action"
      style={{ padding: "32px 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-16 d-flex justify-content-center">
            {content.fieldHeading && <h2>{content.fieldHeading}</h2>}

            {content.fieldCallToAction && (
              <ErrorBoundary>
                <ComponentClickSuggestionLink
                  className="btn btn-primary"
                  link={content.fieldCallToAction}
                />
              </ErrorBoundary>
            )}

            {content.fieldText && (
              <div
                style={{ marginTop: "2rem" }}
                className="text"
                dangerouslySetInnerHTML={{
                  __html: content.fieldText.processed,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const InternalSoundCityTeasers = ({ currentUserRoles }) => {
  const content = [
    {
      entityBundle: "call_to_action",
      entityId: "1793",
      fieldCallToAction: {
        title: "Zum Anmeldeformular für Sound City 2023",
        url: {
          __typename: "EntityCanonicalUrl",
          routed: true,
          path: "/intern/sound-city",
        },
      },
      fieldText: {
        value:
          "<p>Hier können Sie eigenhändig Ensembles und Gruppen für Ihre Schule anmelden.</p>\r\n",
        processed:
          "<p>Hier können Sie eigenhändig Ensembles und Gruppen für Ihre Schule anmelden.</p>\n",
      },
      //fieldHeading: "Zum Anmeldeformular für Sound City 2023",
      fieldAlternativeFarbe: false,
      roles: [
        "admin",
        "erweiterte_redaktion",
        "redakteur",
        "interne",
        "sound_city",
      ],
    },
    {
      entityBundle: "call_to_action",
      entityId: "1796",
      fieldCallToAction: {
        title: "Meine Eingaben",
        url: {
          routed: false,
          path: "/intern/sound-city/eingaben",
        },
      },
      fieldText: {
        value:
          "<p>Hier finden Sie eine Übersicht über die Anmeldungen Ihrer Einrichtung. Änderungen und Korrekturen sind einschließlich bis zum 30. April möglich.</p>\r\n",
        processed:
          "<p>Hier finden Sie eine Übersicht über die Anmeldungen Ihrer Einrichtung. Änderungen und Korrekturen sind einschließlich bis zum 30. April möglich.</p>\n",
      },
      //fieldHeading: "Meine Eingaben",
      fieldAlternativeFarbe: false,
      roles: [
        "admin",
        "erweiterte_redaktion",
        "redakteur",
        "interne",
        "sound_city",
      ],
    },
  ];

  return (
    <div className="container">
      <div className="row">
        {content.map((item, index) => {
          if (!item.roles.some((role) => currentUserRoles.includes(role))) {
            return false;
          }

          return (
            <div
              className={classNames({
                "col-16 col-md-5": false,
                "offset-md-1": false,
                "col-16": true,
              })}
            >
              <CTATeaser content={item} key={index} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InternalSoundCityTeasers;
