import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import classNames from "classnames";

import { states } from "../states";
import { getValueFromStringObjPath } from "../webform-elements";
const UrlField = ({ formik, item, compositeIndex, compositeParent }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  const value = compositeParent
    ? compositeIndex
      ? getValueFromStringObjPath(formik.values, compositeParent)[
          compositeIndex
        ][item.id]
      : getValueFromStringObjPath(formik.values, compositeParent)[item.id]
    : formik.values[item.id];
  const identifier = compositeParent
    ? compositeIndex
      ? `${compositeParent}[${compositeIndex}][${item.id}]`
      : `${compositeParent}[${item.id}]`
    : item.id;

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={identifier}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <Field
        id={identifier}
        name={identifier}
        type="url"
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
        className="form-control"
        placeholder={item.placeholder != null ? item.placeholder : ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={value}
        maxLength={item.maxLength ? item.maxLength : null}
        minLength={item.minLength ? item.minLength : null}
      />

      <ErrorMessage component="span" name={item.id} className="error-message" />
      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

UrlField.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default UrlField;
