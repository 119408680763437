import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import moment from "moment";

import teaserNodeQueryEventDateFiltered from "./../../../teaser-queries/teaser-node-query-event-date-filtered.graphql";
import TeaserEvent from "../../../teaser/teaser-event";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphKalenderAbschnitt extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-kalender-abschnitt": true,
      "heading-only": this.props.content.fieldNurUeberschriftZeigen,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="row">
            <div className="col-16">
              <h2>{this.props.content.fieldHeading}</h2>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            {!this.props.content.fieldNurUeberschriftZeigen &&
              this.props.nodes.nodeQuery &&
              this.props.nodes.nodeQuery.entities &&
              this.props.nodes.nodeQuery.entities.length > 0 && (
                <>
                  {this.props.nodes.nodeQuery.entities.map((item, index) => (
                    <ErrorBoundary key={index}>
                      <TeaserEvent item={item} />
                    </ErrorBoundary>
                  ))}
                </>
              )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphKalenderAbschnitt.propTypes = {
  content: PropTypes.object.isRequired,
  nodes: PropTypes.object,
};

export default graphql(teaserNodeQueryEventDateFiltered, {
  name: "nodes",
  skip: (props) => props.content.fieldNurUeberschriftZeigen,
  options: (props) => {
    let relativeDate = moment().format("YYYY-MM-DD"),
      relativeEndDate = moment().format("YYYY-MM-DD");

    if (props.content.fieldZeitraumAuswaehlenNach === "relative") {
      switch (props.content.fieldRelativesDatum) {
        case "today":
          relativeDate = moment().subtract(1, "days").format("YYYY-MM-DD");
          relativeEndDate = moment().format("YYYY-MM-DD");
          break;
        case "tomorrow":
          relativeDate = moment().format("YYYY-MM-DD");
          relativeEndDate = moment().add(1, "days").format("YYYY-MM-DD");
          break;
        case "this week":
          relativeDate = moment().startOf("week").format("YYYY-MM-DD");
          relativeEndDate = moment().endOf("week").format("YYYY-MM-DD");
          break;
        case "next week":
          relativeDate = moment()
            .add(1, "weeks")
            .startOf("week")
            .format("YYYY-MM-DD");
          relativeEndDate = moment()
            .add(1, "weeks")
            .endOf("week")
            .format("YYYY-MM-DD");
          break;
        case "this month":
          relativeDate = moment().startOf("month").format("YYYY-MM-DD");
          relativeEndDate = moment().endOf("month").format("YYYY-MM-DD");
          break;
        case "next month":
          relativeDate = moment()
            .add(1, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
          relativeEndDate = moment()
            .add(1, "months")
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
      }
    }

    return {
      variables: {
        type: "veranstaltung",
        end_date:
          props.content.fieldZeitraumAuswaehlenNach === "static"
            ? props.content.fieldFestesDatum.endValue
            : relativeEndDate,
        date:
          props.content.fieldZeitraumAuswaehlenNach === "static"
            ? props.content.fieldFestesDatum.value
            : relativeDate,
      },
    };
  },
})(ParagraphKalenderAbschnitt);
