import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "./../../../../general-components/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Collapse } from "react-collapse";

import EditButton from "../../../../backend/edit-button";
import {self} from "../../../../config";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite,
});

class TeaserProgramm extends Component {
  static defaultProps = { pagerFullPage: false };

  state = {
    open: false,
  };

  toggleCollapse = (content) => {
    if (content.fieldBeschreibung !== null || content.fieldTermine.length > 0) {
      this.setState({ open: !this.state.open });
    }
  };

  render() {
    return (
      <article
        className={`node node-${this.props.item.entityId} node-teaser teaser-programm col-16`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-3 date-wrapper">
              <div className="date">
                <div className={"day"}>
                  <span className="day-name">
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("ddd")
                    }
                  </span>
                  <span className="day-date">
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("DD")
                    }
                  </span>
                </div>
                <div className={"time"}>
                  <span>
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("HH:mm")
                    }
                  </span>
                </div>
                {/*this.props.item.fieldDatum.endValue &&
                    moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("DD.MM.") !==
                    moment
                      .utc(this.props.item.fieldDatum.endValue)
                      .local()
                      .format("DD.MM.") && (
                      <>
                        –{" "}
                        {moment
                          .utc(this.props.item.fieldDatum.endValue)
                          .local()
                          .format("DD.MM.")}
                      </>
                    )*/}
              </div>
            </div>
            <div
              className={`col-16 col-md-8 offset-xl-0`}
            >
              <h3>{this.props.item.title}</h3>
              {this.props.item.fieldSchlagwort.length > 0 &&
                <div className="tag-wrapper">
                  {this.props.item.fieldSchlagwort.map((item, index) => (
                    <span key={index} className="tag">
                      {item.entity.name}
                    </span>
                  ))}
                </div>
              }
              {this.props.item.fieldBuehne &&
                <div className="location">
                  <>{this.props.item.fieldBuehne.entity.name}</>
                  {this.props.item.fieldBuehne.entity.fieldMapsLink &&
                    <a href={this.props.item.fieldBuehne.entity.fieldMapsLink} target={"_blank"}>Karte</a>
                  }
                </div>

              }
              {Boolean(this.props.item.fieldText) && (
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.fieldText.value,
                  }}
                />
              )}
              {Boolean(this.props.item.fieldBeschreibung) &&
                 <>
                   <button className={`toggle-collapse ${this.state.open ? 'opened' : ''}`} onClick={() => this.toggleCollapse(this.props.item)}>
                     <span>Mehr dazu</span>
                     <img
                       className="chevron"
                       src={`${self}/sound-city/ui/chevron/xs/down.svg`}
                       alt="mehr icon"
                       title="Mehr dazu Icon"
                     />
                   </button>
                   <Collapse isOpened={this.state.open}>
                     <>
                       {Boolean(this.props.item.fieldBeschreibung) && (
                         <div
                           className="description"
                           dangerouslySetInnerHTML={{
                             __html: this.props.item.fieldBeschreibung.value,
                           }}
                         />
                       )}
                     </>
                   </Collapse>
                 </>
              }
            </div>
            <div className="col-16 col-md-4 col-lg-5 image-col">
              {this.props.item.fieldBild !== null && (
                <Image
                  data={this.props.item.fieldBild.entity.fieldMediaImage}
                  nodeTitle={this.props.item.title}
                />
              )}
            </div>
          </div>
        </div>
      </article>
    );
  }
}

TeaserProgramm.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserProgramm));