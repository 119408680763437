import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { YouTubeVideo } from "./component-youtube";
import { VimeoVideo } from "./component-vimeo";
import ComponentVideo from "./component-video";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphMedia extends Component {
  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-media": true,
      }),
      { content } = this.props;

    switch (content.fieldMediaArt) {
      case "youtube":
        return (
          <ErrorBoundary>
            <YouTubeVideo
              content={content}
              sectionClassNames={sectionClassNames}
            />
          </ErrorBoundary>
        );
      case "vimeo":
        return (
          <ErrorBoundary>
            <VimeoVideo
              content={content}
              sectionClassNames={sectionClassNames}
            />
          </ErrorBoundary>
        );
      case "video_file":
        return (
          <ErrorBoundary>
            <ComponentVideo
              content={content}
              sectionClassNames={sectionClassNames}
            />
          </ErrorBoundary>
        );
      default:
        return false;
    }
  }
}

ParagraphMedia.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphMedia;
