import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Image from "../image";
import TeaserPersonOverlay from "./teaser-person-overlay";
import EditButton from "../../backend/edit-button";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Rename. This is the person teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
class TeaserPersonOverview extends Component {
  state = { isOpen: false };

  toggleOverlay = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        document.querySelector("html").style.overflow = "visible";
      });
    } else {
      this.setState({ isOpen: true }, () => {
        document.querySelector("html").style.overflow = "hidden";
      });
    }
  };

  render() {
    return (
      <>
        <article className="node node-teaser teaser-person teaser-person-overview">
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <div className="flex-wrap" /*onClick={() => this.toggleOverlay()}*/>
            <Image
              data={this.props.item.fieldBildWCaption.entity.fieldMediaImage}
              nodeTitle={this.props.item.title}
            />
            <h3>{this.props.item.title}</h3>

            {this.props.item.fieldRolleOderFunktion && (
              <div className="role d-flex">
                {this.props.item.fieldRolleOderFunktion.map((item, index) => (
                  <>
                    <span key={index} className="top-line">
                      {index > 0 && ", "}
                      {item.entity.name}
                    </span>
                  </>
                ))}
              </div>
            )}

            {this.props.item.fieldMail && (
              <a className="mail" href={`mailto:${this.props.item.fieldMail}`}>
                {this.props.item.fieldMail}
              </a>
            )}

            {this.props.item.fieldTelefon && (
              <span className="phone">{this.props.item.fieldTelefon}</span>
            )}
          </div>
        </article>
        {this.state.isOpen && (
          <div className="person-overlay-bg">
            <div className="person-overlay">
              <div className="container">
                <TeaserPersonOverlay
                  item={this.props.item}
                  toggleOverlay={this.toggleOverlay}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonOverview));
