import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Download24 } from "@carbon/icons-react";
import Image from "../../image";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite
});

class ParagraphDownload extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className={`col-16 ${!this.props.microSite ? 'col-md-15 offset-md-1' : ''}`}>
              <div className="row">
                {this.props.microSite ? (
                  <>
                    {this.props.content.fieldDatei.entity.fieldVorschaubild !==
                      null && (
                        <div className="col-16 col-md-8">
                          <Image
                            data={
                              this.props.content.fieldDatei.entity.fieldVorschaubild
                                .entity.fieldMediaImage
                            }
                          />
                        </div>
                      )}
                    <div
                      className={classNames({
                        "col-16":
                          this.props.content.fieldDatei.entity.fieldVorschaubild ===
                          null || this.props.microSite == false,
                        "col-16 col-md-8":
                          this.props.content.fieldDatei.entity.fieldVorschaubild !==
                          null || this.props.microSite,
                      })}
                    >
                      {this.props.content.fieldDatei.entity.fieldBeschreibung && (
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html:
                            this.props.content.fieldDatei.entity.fieldBeschreibung
                              .value,
                          }}
                        />
                      )}
                      <a
                        className="btn btn-primary"
                        href={
                          this.props.content.fieldDatei.entity.fieldMediaFile.entity
                            .url
                        }
                        download={true}
                      >
                        <Download24 />
                        <span>{this.props.content.fieldDatei.entity.entityLabel}</span>
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    {this.props.content.fieldDatei.entity.fieldVorschaubild !==
                      null && (
                        <div className="col-2">
                          <Image
                            data={
                              this.props.content.fieldDatei.entity.fieldVorschaubild
                                .entity.fieldMediaImage
                            }
                          />
                        </div>
                      )}
                    <div
                      className={classNames({
                        "col-10":
                          this.props.content.fieldDatei.entity.fieldVorschaubild ===
                          null,
                        "col-8":
                          this.props.content.fieldDatei.entity.fieldVorschaubild !==
                          null,
                      })}
                    >
                      <h3>{this.props.content.fieldDatei.entity.entityLabel}</h3>
                      {this.props.content.fieldDatei.entity.fieldBeschreibung && (
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html:
                            this.props.content.fieldDatei.entity.fieldBeschreibung
                              .value,
                          }}
                        />
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <a
                        className="btn btn-primary"
                        href={
                          this.props.content.fieldDatei.entity.fieldMediaFile.entity
                            .url
                        }
                        download={true}
                      >
                        <Download24 />
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphDownload.propTypes = {
  content: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ParagraphDownload);
