import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ComponentClickSuggestionLink from "./component-click-suggestion-link";
import ErrorBoundary from "../../../error-boundary";

class ParagraphCallToAction extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-call-to-action": true,
      "alternative-color": this.props.content.fieldAlternativeFarbe,
      "with-text": this.props.content.fieldText,
      "with-heading": this.props.content.fieldHeading,
      "link-only": !this.props.content.fieldText && !this.props.content.fieldHeading,
      colored: this.props.content.fieldFarbigHinterlegen,
    });
    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 d-flex justify-content-center">
              {this.props.content.fieldHeading && (
                <h2>{this.props.content.fieldHeading}</h2>
              )}
              {this.props.content.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldText.processed,
                  }}
                />
              )}
              {this.props.content.fieldCallToAction && (
                <ErrorBoundary>
                  <ComponentClickSuggestionLink
                    className="btn btn-primary"
                    link={this.props.content.fieldCallToAction}
                  />
                </ErrorBoundary>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphCallToAction.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphCallToAction;
