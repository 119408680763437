import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import Image from "../../../general-components/image";

/**
 * TODO: fieldSetAnchorLink.
 */
class ParagraphSCTitel extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sound-city-titel": true,
      "with-image": this.props.content.fieldImage,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container sticky">
          <div className="row justify-content-between">
            <div className="col-15 col-lg-12 col-xl-14">
              <h1 className="heading">
                {this.props.content.fieldHeading}
              </h1>
            </div>
          </div>
        </div>
        {this.props.content.fieldImage &&
          <div className="container keep-size">
            <div className="row justify-content-between">
              <div className="col-16">
                <Image
                  data={this.props.content.fieldImage.entity.fieldMediaImage}
                  nodeTitle={this.props.content.fieldHeading}
                />
              </div>
            </div>
          </div>
        }
      </section>
    );
  }
}

ParagraphSCTitel.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphSCTitel;
