import React from "react";
import PropTypes from "prop-types";
import { states } from "../states";

const HiddenField = ({ formik, item }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  return (
    <div className="form-group">
      <input
        id={item.id}
        name={item.id}
        type="hidden"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[item.id]}
      />
    </div>
  );
};

HiddenField.prototypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default HiddenField;
