import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

import TeaserListWrap from "./components/component-teaserlist-wrap";
import TeaserEventList from "./events/component-teaser-event-list";
import ComponentTeaserlistListSlick from "./components/component-teaserlist-list-slick";
import ComponentClickSuggestionLink from "../component-click-suggestion-link";
import ErrorBoundary from "../../../../error-boundary";
import {connect} from "react-redux";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite
});


class ParagraphTeaserList extends Component {
  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-teaser-list": true,
        [`paragraph-teaser-list-${this.props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
        "paragraph paragraph-teaser-list-slick":
          this.props.content.fieldKarussel && !this.props.microSite,
      }),
      eventOnly = this.props.content.fieldTyp === "veranstaltung";

    let manualNodes = this.props.content.fieldBeitraegeAll;

    switch (this.props.content.fieldTyp) {
      case "news": {
        manualNodes = this.props.content.fieldBeitraegeNews;

        break;
      }
      case "person": {
        manualNodes = this.props.content.fieldBeitraegePerson;

        break;
      }
      default: {
        manualNodes = this.props.content.fieldBeitraegeAll;
      }
    }

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16 offset-xl-1 col-xl-14 title">
                <Hyphenated language={de}>
                  <h2>{this.props.content.fieldHeading}</h2>
                </Hyphenated>
              </div>
            </div>
          </div>
        )}

        {eventOnly && (
          <TeaserEventList
            count={this.props.content.fieldAnzahlDerAnzuzeigendenI}
            manualNodes={this.props.content.fieldBeitraegeVeranstaltung}
            nodesConfig={this.props.content.fieldAutmatischOderManuell}
            type={this.props.content.fieldTyp}
            tags={this.props.content.fieldSchlagwort}
          />
        )}

        {!eventOnly && this.props.content.fieldKarussel && !this.props.microSite && (
          <ComponentTeaserlistListSlick
            manualNodes={manualNodes}
            id={this.props.content.entityId}
            nodesConfig={this.props.content.fieldAutmatischOderManuell}
            count={this.props.content.fieldAnzahlDerAnzuzeigendenI}
            type={this.props.content.fieldTyp}
            pagerFullPage={
              this.props.content.fieldPagerAufVollseiten
                ? this.props.content.entityId
                : false
            }
            pagerFullPageOverviewLink={
              this.props.content.fieldLinkZurUebersicht
            }
            tags={this.props.content.fieldSchlagwort}
          />
        )}

        {(!eventOnly && !this.props.content.fieldKarussel) || this.props.microSite && (
          <div className="container">
            <div className="row">
              <TeaserListWrap
                manualNodes={manualNodes}
                id={this.props.content.entityId}
                nodesConfig={this.props.content.fieldAutmatischOderManuell}
                count={this.props.content.fieldAnzahlDerAnzuzeigendenI}
                type={this.props.content.fieldTyp}
                pagerFullPage={
                  this.props.content.fieldPagerAufVollseiten
                    ? this.props.content.entityId
                    : false
                }
                pagerFullPageOverviewLink={
                  this.props.content.fieldLinkZurUebersicht
                }
                tags={this.props.content.fieldSchlagwort}
              />
            </div>
          </div>
        )}

        {this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton.title && (
            <div className="container">
              <div className="row">
                <div className="col-16 d-md-flex justify-content-md-end">
                  <ComponentClickSuggestionLink
                    link={this.props.content.fieldMehrMeldungenButton}
                    className="btn btn-primary more"
                  />
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphTeaserList.propTypes = {
  content: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ParagraphTeaserList);
