import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { states } from "../states";

const MarkupField = ({ formik, item }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  return (
    <div
      className={classNames({
        markup: true,
        hidden: invisible || !visible,
      })}
      dangerouslySetInnerHTML={{
        __html: item.markup,
      }}
      style={item.flex ? { flex: item.flex } : {}}
    />
  );
};

MarkupField.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default MarkupField;
