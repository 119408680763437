import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TeaserNews from "../../teaser/teaser-news";
import TeaserPerson from "../../teaser/teaser-person";
import ErrorBoundary from "../../../error-boundary";
import TeaserBase from "../../teaser-base";

/**
 * @todo Sync with module-box-vs
 * @todo Error Boundaries.
 */
class ParagraphDossier extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-dossier": true,
      "with-background-image": this.props.content.fieldImage !== null,
    });
    let customStyle = {};

    if (this.props.content.fieldImage !== null) {
      customStyle = {
        background: `url(${this.props.content.fieldImage.entity.fieldMediaImage.style.url})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }
    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldImage !== null && (
          <div className={"background-image"} style={customStyle} />
        )}
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-10 offset-lg-1">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-14 offset-lg-1">
              <div className="row items justify-content-center">
                <div className="col-16 col-md-6 lg-8">
                  {(() => {
                    switch (
                      this.props.content.fieldBeitraegeDossier[0].entity
                        .entityBundle
                    ) {
                      case "news":
                        return (
                          <ErrorBoundary>
                            <TeaserNews
                              item={
                                this.props.content.fieldBeitraegeDossier[0]
                                  .entity
                              }
                            />
                          </ErrorBoundary>
                        );
                      case "person":
                        return (
                          <ErrorBoundary>
                            <TeaserPerson
                              item={
                                this.props.content.fieldBeitraegeDossier[0]
                                  .entity
                              }
                            />
                          </ErrorBoundary>
                        );
                      case "projekt":
                      case "schule":
                      case "landingpage":
                        return (
                          <TeaserBase
                            item={
                              this.props.content.fieldBeitraegeDossier[0].entity
                            }
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
                {this.props.content.fieldBeitraegeDossier[1] && (
                  <div className="col-16 col-md-5 lg-4">
                    {(() => {
                      switch (
                        this.props.content.fieldBeitraegeDossier[1].entity
                          .entityBundle
                      ) {
                        case "news":
                          return (
                            <TeaserNews
                              item={
                                this.props.content.fieldBeitraegeDossier[1]
                                  .entity
                              }
                            />
                          );
                        case "person":
                          return (
                            <TeaserPerson
                              item={
                                this.props.content.fieldBeitraegeDossier[1]
                                  .entity
                              }
                            />
                          );
                        case "projekt":
                        case "schule":
                        case "landingpage":
                          return (
                            <TeaserBase
                              item={
                                this.props.content.fieldBeitraegeDossier[1]
                                  .entity
                              }
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                )}
                {this.props.content.fieldBeitraegeDossier[2] && (
                  <div className="col-16 col-md-5 lg-4">
                    {(() => {
                      switch (
                        this.props.content.fieldBeitraegeDossier[2].entity
                          .entityBundle
                      ) {
                        case "news":
                          return (
                            <TeaserNews
                              item={
                                this.props.content.fieldBeitraegeDossier[2]
                                  .entity
                              }
                            />
                          );
                        case "person":
                          return (
                            <TeaserPerson
                              item={
                                this.props.content.fieldBeitraegeDossier[2]
                                  .entity
                              }
                            />
                          );
                        case "projekt":
                        case "schule":
                        case "landingpage":
                          return (
                            <TeaserBase
                              item={
                                this.props.content.fieldBeitraegeDossier[2]
                                  .entity
                              }
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphDossier.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphDossier;
