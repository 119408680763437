import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import ExecutionEnvironment from "exenv";
import classNames from "classnames";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import ErrorBoundary from "../error-boundary";
import ParagraphText from "../general-components/content-base/paragraphs/paragraph-text";
import SoundCityIntro from "../microsites/sound-city/sound-city-intro";
import InternalSoundCityTeasers from "../intern/sound-city/internal-sound-city-teasers";
import { currentNodeIdAction } from "../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  auth: reduxStore.auth,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
});

class Landingpage extends Component {
  constructor(props) {
    super(props);

    if (props.data.route) {
      props.dispatch(
        currentNodeIdAction(this.props.data.route.entity.entityId)
      );
    }
  }

  componentDidMount() {
    if (this.props.data.route) {
      this.props.dispatch(
        currentNodeIdAction(this.props.data.route.entity.entityId)
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data.route &&
      this.props.data.route.entity.entityId !==
        prevProps.data?.route?.entity?.entityId
    ) {
      this.props.dispatch(
        currentNodeIdAction(this.props.data.route.entity.entityId)
      );
    }
  }

  render() {
    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      if (
        this.props.auth.userLoginData.logout_token &&
        ExecutionEnvironment.canUseDOM &&
        this.props.auth.userLoginData.temp_pass
      ) {
        return <Redirect to="/intern/reset-pass/temp" />;
      }

      if (
        !this.props.auth.userLoginData.logout_token &&
        ExecutionEnvironment.canUseDOM &&
        !document.body.classList.contains("user-logged-in") &&
        this.props.location.pathname.includes("/intern/")
      ) {
        return <Redirect to="/intern/login" />;
      }

      return (
        <article
          className={classNames({
            [`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published}`]: true,
            "front-page": this.props.location.pathname === "/",
          })}
        >
          <Helmet>
            <title>{`Verband der Musik- und Kunstschulen Brandenburg | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>

          {this.props.location.pathname === "/" &&
          this.props.microSite === "sound-city" ? (
            <SoundCityIntro />
          ) : null}

          {this.props.data.route.entity.fieldModules && (
            <ContentBase
              content={this.props.data.route.entity.fieldModules}
              location={this.props.location}
            />
          )}

          {/* eslint-disable-next-line no-inline-comments */}
          {/* Quick and dirty fix for http://office.e-fork.net:8080/issues/9097 */}
          {this.props.data.route.entity.entityId === "256" && (
            <ErrorBoundary>
              <div style={{ marginTop: "-64px" }}>
                <ParagraphText
                  content={{
                    fieldFarbigHinterlegen: true,
                    fieldText: {
                      processed:
                        'Um Ihre persönlichen Cookie-Einstellungen zu ändern, klicken Sie bitte <a href="#" onClick="UC_UI.showSecondLayer();">hier!</a>',
                    },
                  }}
                />
              </div>
            </ErrorBoundary>
          )}

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true} />;
  }
}

Landingpage.propTypes = {
  auth: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  microSite: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  microSiteFrontPage: PropTypes.string,
};

export default connect(mapStateToProps)(
  graphql(landingPageQuery, {
    options: (props) => ({
      variables: {
        path:
          props.location.pathname === "/" &&
          props.microSite &&
          props.microSiteFrontPage !== ""
            ? props.microSiteFrontPage
            : props.location.pathname,
      },
    }),
  })(withRouter(Landingpage))
);
