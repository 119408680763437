import React, { Component } from "react";
import SimpleNavigation from "./simple-navigation";
import LogoButton from "./logo-button";
import { connect } from "react-redux";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite,
});

class SimpleHeader extends Component {
  render() {
    return (
      <div className="container header-container">
        <div className="row flex-md-row align-items-md-start">
          <div className="col-8 left-side">
            <LogoButton location={this.props.location} />
          </div>
          <div className="col-8 right-side">
            <SimpleNavigation
              menuName={
                !this.props.microSite ? "main" : "hauptmenue-sound-city"
              }
              footerConfig={this.props.footerConfig}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SimpleHeader);
