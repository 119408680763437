import React from "react";

function ScReg() {
  return (
    <svg width="276" height="168" viewBox="0 0 276 168">
      <path d="M.63 43.92c0 14.67 12.78 23.76 29.79 23.76 17.19 0 28.26-7.11 28.26-20.88 0-10.8-6.93-15.75-19.26-19.26L28.17 24.3c-4.5-1.26-8.1-2.61-8.1-5.58 0-2.88 2.88-4.59 8.19-4.59 6.03 0 11.52.99 11.52 7.65h18.45C57.06 4.95 44.82 0 28.98 0 12.42 0 1.71 7.92 1.71 20.43c0 10.53 7.74 15.66 15.39 17.91l17.82 5.31c2.88.9 5.4 2.07 5.4 4.95 0 2.88-2.97 4.77-9.36 4.77-6.66 0-11.52-2.34-11.88-9.45H.63z"></path>
      <path d="M87.09 67.68c15.66 0 25.47-9.45 25.47-24.12 0-14.22-9.81-24.48-25.47-24.48-15.66 0-25.56 10.26-25.56 24.48 0 14.67 9.9 24.12 25.56 24.12zm-7.56-24.12c0-8.55 3.6-11.97 7.56-11.97 3.96 0 7.47 3.42 7.47 11.97 0 8.46-3.51 11.61-7.47 11.61s-7.56-3.15-7.56-11.61z"></path>
      <path d="M166.71 65.88v-45h-17.55v18.63c-.27 9.9-4.23 13.95-9.09 13.95-3.6 0-5.58-2.34-5.67-7.2l-.54-25.38h-17.55l.54 29.97c.18 10.62 6.3 16.47 16.38 16.47 4.05 0 6.93-.9 9.63-3.15 1.17-.99 1.8-2.25 3.42-2.25 1.71 0 2.88.99 2.88 3.96h17.55zM172.71 20.88v45h17.55V47.25c.27-9.9 4.23-13.95 9.09-13.95 3.6 0 5.58 2.34 5.67 7.2l.54 25.38h17.55l-.54-29.97c-.18-10.62-6.3-16.47-16.38-16.47-4.05 0-6.93.9-9.63 3.15-1.17.99-1.8 2.25-3.42 2.25-1.71 0-2.88-.99-2.88-3.96h-17.55z"></path>
      <path d="M246.3 67.59c3.96 0 7.38-.99 9.45-2.34 1.71-1.17 3.51-1.35 4.68 0l.54.63h15.39V1.8h-17.55v17.46c0 2.7-2.25 3.15-3.78 2.25-1.62-.99-3.33-2.43-8.55-2.43-9.45 0-19.62 8.28-19.62 24.48 0 16.47 9.99 24.03 19.44 24.03zm-1.89-23.94c0-8.55 3.6-12.24 7.56-12.24 4.86 0 7.38 3.69 7.38 12.24 0 8.73-2.52 11.7-7.38 11.7-3.96 0-7.56-2.97-7.56-11.7z"></path>
      <path d="M44.28 128.09c-.81 4.95-5.67 7.56-11.07 7.56-8.73 0-14.49-8.91-14.49-19.08 0-10.44 5.49-18.45 14.22-18.45 6.3 0 10.71 3.15 11.7 8.64h17.55c-1.08-14.4-14.94-23.04-29.34-23.04C13.32 83.72 0 97.13 0 116.48c0 20.07 13.32 33.75 32.85 33.75 13.68 0 26.82-6.75 29.43-22.14h-18z"></path>
      <path d="M66.3 103.88v45h17.55v-45H66.3zm-.72-18.27c0 5.04 4.5 9.54 9.54 9.54 5.13 0 9.54-4.5 9.54-9.54 0-5.13-4.41-9.63-9.54-9.63-5.04 0-9.54 4.5-9.54 9.63z"></path>
      <path d="M122.79 146.9v-11.34c-7.92 4.14-12.06.45-12.06-6.48v-12.69h9.36v-12.51h-1.17c-5.4 0-8.19-2.7-8.19-8.1V83.45h-.45l-17.1 14.4v2.16c0 2.7-1.53 3.87-4.05 3.87h-1.17v12.51h.72c2.7 0 4.5 1.17 4.5 4.5v12.15c0 4.05 1.44 9.99 6.48 14.22 5.85 4.95 17.82 4.23 23.13-.36zM134.37 167.96h17.46l26.1-64.08h-18.27l-3.96 13.14c-1.26 4.23-7.29 4.23-8.82 0l-4.77-13.14h-18.18l19.35 43.74-8.91 20.34z"></path>
    </svg>
  );
}

export default ScReg;
