import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";

class FieldTime extends Component {
  render() {
    const date = this.props.timestamp
      ? this.props.date
      : this.props.date.replace(" UTC", "").trim().replace(" ", "T");

    return (
      <time
        dateTime={
          this.props.timestamp
            ? moment.unix(date).format("YYYY-MM-DD")
            : this.props.date.replace(" UTC", "Z").replace(" ", "T")
        }
      >
        <span
          className={classNames({
            "d-none d-md-block": typeof this.props.mobileFormat !== "undefined",
          })}
        >
          {this.props.timestamp ? (
            <>{moment.unix(date).format(this.props.format)}</>
          ) : (
            <>{moment(date).format(this.props.format)}</>
          )}
        </span>
        {this.props.mobileFormat && (
          <span className="d-block d-md-none">
            {this.props.timestamp ? (
              <>{moment.unix(date).format(this.props.mobileFormat)}</>
            ) : (
              <>{moment(date).format(this.props.mobileFormat)}</>
            )}
          </span>
        )}
      </time>
    );
  }
}

FieldTime.propTypes = {
  // Use dateField.value as date prop.
  date: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  mobileFormat: PropTypes.string,
  timestamp: PropTypes.bool,
};

export default FieldTime;
