import { useEffect, useState } from "react";
import React, {Component} from 'react';

export default function useMousePosition(ref) {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const mouseMoveHandler = (event) => {
      const { clientX, clientY } = event;

      setMousePosition({ x: clientX - 25, y: clientY - ref.getBoundingClientRect().top - 50});
    };
    ref.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      ref.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return mousePosition;
}