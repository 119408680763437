import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TeaserBase from "../../../teaser-base";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphKalenderEinzelDoppel extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-kalender-einzel-doppel": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              {this.props.content.fieldHeading && (
                <h2>{this.props.content.fieldHeading}</h2>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          {this.props.content.fieldEvents.map((item, index) => (
            <div
              key={index}
              className={classNames({
                "col-16": true,
                "col-md-14 offset-md-1":
                  this.props.content.fieldEvents.length === 1,
                "col-md-6 offset-md-1":
                  this.props.content.fieldEvents.length > 1 && index === 0,
                "col-md-6 offset-md-2":
                  this.props.content.fieldEvents.length > 1 && index === 1,
              })}
            >
              <ErrorBoundary>
                <TeaserBase
                  item={item.entity}
                  viewMode={"doppel-einzel"}
                  single={this.props.content.fieldEvents.length === 1}
                />
              </ErrorBoundary>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

ParagraphKalenderEinzelDoppel.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphKalenderEinzelDoppel;
