import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { connect } from "react-redux";
import { List32, Grid32 } from "@carbon/icons-react";

import teaserNodeQuery from "../../../teaser-queries/teaser-node-query.graphql";
import teaserNodeQueryFilterTag from "../../../teaser-queries/teaser-node-query--filter-tag.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base";
import ComponentFilterCloud from "../extended-teaser-list/components/component-filter-cloud";
import {
  timelineSetActiveFilterAction,
  timelineRemoveActiveFilterAction,
} from "./actions-timeline";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{timelineConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  timelineConfig: reduxStore.timeLine.config,
});

/*
 * @todo: Pager.
 */
class ParagraphTimeline extends Component {
  state = {
    activeFilter: {},
    displaySwitch: "grid",
  };

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      this.state.activeFilter.entityId === activeFilter.entityId
    ) {
      this.setState({ activeFilter: {} }, () => {
        this.props.dispatch(
          timelineRemoveActiveFilterAction(this.props.content.entityId)
        );
      });
    } else {
      this.setState({ activeFilter }, () => {
        const config = {
          timeLineId: this.props.content.entityId,
          config: activeFilter,
        };

        this.props.dispatch(timelineSetActiveFilterAction(config));
      });
    }
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-timeline": true,
    });

    let items = [],
      loadMore = false,
      loadMoreAction = "unfiltered";

    // Get active query - filtered or unfiltered.
    if (Object.keys(this.state.activeFilter).length === 0) {
      // Unfiltered
      items =
        this.props.nodes.nodeQuery &&
        this.props.nodes.nodeQuery.entities.length > 0
          ? this.props.nodes.nodeQuery.entities
          : [];
      loadMore =
        this.props.nodes.nodeQuery &&
        this.props.nodes.nodeQuery.entities.length > 0 &&
        this.props.nodes.nodeQuery.entities.length <
          this.props.nodes.nodeQuery.count;
    } else {
      // Filterd.
      items =
        this.props.nodesFiltered.nodeQuery &&
        this.props.nodesFiltered.nodeQuery.entities.length > 0
          ? this.props.nodesFiltered.nodeQuery.entities
          : [];
      loadMore =
        this.props.nodesFiltered.nodeQuery &&
        this.props.nodesFiltered.nodeQuery.entities.length > 0 &&
        this.props.nodesFiltered.nodeQuery.entities.length <
          this.props.nodesFiltered.nodeQuery.count;
      loadMoreAction = "filtered";
    }

    if (items.length > 0) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            {(this.props.content.fieldFilterwolke ||
              this.props.content.fieldListenumschalter) && (
              <div className="row timeline-filter">
                <div className="filter col-16 col-md-14">
                  {this.props.content.fieldFilterwolke && (
                    <ComponentFilterCloud
                      items={this.props.nodes.nodeQuery.entities}
                      filter="fieldSchlagwort"
                      activeFilter={this.state.activeFilter}
                      changeActiveFilter={this.changeActiveFilter}
                      wrap={false}
                    />
                  )}
                </div>
                <div className="list-switch col-16 col-md-2 d-flex justify-content-end">
                  {this.props.content.fieldListenumschalter && (
                    <>
                      <List32
                        className={
                          this.state.displaySwitch === "list" ? "active" : ""
                        }
                        onClick={() => this.setState({ displaySwitch: "list" })}
                      />
                      <Grid32
                        className={
                          this.state.displaySwitch === "grid" ? "active" : ""
                        }
                        onClick={() => this.setState({ displaySwitch: "grid" })}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div
                className={classNames({
                  "col-16": true,
                  "col-lg-14 offset-lg-1": this.state.displaySwitch === "grid",
                })}
              >
                <div className="row">
                  {this.state.displaySwitch === "grid" ? (
                    <>
                      <div className="timeline col-1 d-none d-lg-flex flex-lg-column">
                        <div className="top">
                          <span>Jetzt</span>
                        </div>

                        <div className="bottom">
                          <span>Früher</span>
                        </div>
                      </div>

                      <div className="col-16 col-lg-6 offset-lg-1">
                        {items
                          .filter((item, index) => (index + 1) % 2 !== 0)
                          .map((item, index) => (
                            <TeaserBase
                              item={item}
                              key={index}
                              viewMode={"timeline"}
                            />
                          ))}
                      </div>

                      <div className="col-16 col-lg-6 offset-lg-1 timeline-column-1">
                        {items
                          .filter((item, index) => (index + 1) % 2 === 0)
                          .map((item, index) => (
                            <TeaserBase
                              item={item}
                              key={index}
                              viewMode={"timeline"}
                            />
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="col-16">
                      {items.map((item, index) => (
                        <TeaserBase
                          item={item}
                          key={index}
                          viewMode={"timeline-list"}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.props.content.fieldZeigeMehrLaden && loadMore && (
              <div className="row">
                <div className="col-16 d-flex justify-content-end justify-content-md-center">
                  <div
                    className="btn btn-primary"
                    onClick={() =>
                      loadMoreAction === "filtered"
                        ? this.props.nodesFiltered.loadMore()
                        : this.props.nodes.loadMore()
                    }
                  >
                    <span>Weitere laden</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      );
    }

    return <LoadingIndicator />;
  }
}

ParagraphTimeline.propTypes = {
  content: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
  nodesFiltered: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(teaserNodeQuery, {
      name: "nodes",
      options: (props) => {
        const tagConfig = props.timelineConfig.filter(
            (item) => item.timeLineId === props.content.entityId
          ),
          tagFiltered = tagConfig.length === 1 && tagConfig[0].config.entityId;

        return {
          skip: tagFiltered,
          variables: {
            limit: props.content.fieldAnzahlDerAnzuzeigendenI,
            type: props.content.fieldTypenTimeline,
          },
        };
      },
      props: (props) => ({
        nodes: {
          ...props.nodes,
          loadMore: () =>
            props.nodes.fetchMore({
              variables: {
                offset: props.nodes.nodeQuery.entities.length,
              },
              updateQuery(previousResult, { fetchMoreResult }) {
                if (!fetchMoreResult) {
                  return previousResult;
                }

                fetchMoreResult.nodeQuery.entities = [
                  ...previousResult.nodeQuery.entities,
                  ...fetchMoreResult.nodeQuery.entities,
                ];

                return fetchMoreResult;
              },
            }),
        },
      }),
    }),
    graphql(teaserNodeQueryFilterTag, {
      name: "nodesFiltered",
      options: (props) => {
        const tagConfig = props.timelineConfig.filter(
            (item) => item.timeLineId === props.content.entityId
          ),
          tagFiltered = tagConfig.length === 1 && tagConfig[0].config.entityId;

        return {
          skip: !tagFiltered,
          variables: {
            limit: props.content.fieldAnzahlDerAnzuzeigendenI,
            type: props.content.fieldTypenTimeline,
            // TODO: Not tag multi select, just one tag at a time.
            tag: [tagConfig.length === 1 ? tagConfig[0].config.entityId : ""],
          },
        };
      },
      props: (props) => ({
        nodesFiltered: {
          ...props.nodesFiltered,
          loadMore: () =>
            props.nodesFiltered.fetchMore({
              variables: {
                offset: props.nodesFiltered.nodeQuery.entities.length,
              },
              updateQuery(previousResult, { fetchMoreResult }) {
                if (!fetchMoreResult) {
                  return previousResult;
                }

                fetchMoreResult.nodeQuery.entities = [
                  ...previousResult.nodeQuery.entities,
                  ...fetchMoreResult.nodeQuery.entities,
                ];

                return fetchMoreResult;
              },
            }),
        },
      }),
    })
  )(ParagraphTimeline)
);
