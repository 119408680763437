import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphLogosPartners extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-logos-partners": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <div className="heading">{this.props.content.fieldHeading}</div>
            </div>
          </div>
        </div>

        <div className="container logos">
          <div className="row">
            <div
              className={classNames({
                "col-16": true,
                "col-md-12 offset-md-2":
                  this.props.content.fieldLogosPartners.length !== 3,
              })}
            >
              <div
                className={classNames({
                  "row align-items-center": true,
                  "justify-content-center":
                    this.props.content.fieldLogosPartners.length === 1,
                  "justify-content-between":
                    this.props.content.fieldLogosPartners.length > 1,
                })}
              >
                {this.props.content.fieldLogosPartners.map((item, index) => (
                  <div
                    key={index}
                    className={classNames({
                      partner: true,
                      "col-8 col-md-6":
                        this.props.content.fieldLogosPartners.length !== 3,
                      "col-5":
                        this.props.content.fieldLogosPartners.length === 3,
                    })}
                  >
                    {item.entity.fieldLink !== null ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.entity.fieldLink.url.path}
                      >
                        <Image data={item.entity.fieldMediaImage1} />
                      </a>
                    ) : (
                      <ErrorBoundary>
                        <Image data={item.entity.fieldMediaImage1} />
                      </ErrorBoundary>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphLogosPartners.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphLogosPartners;
