import * as React from 'react';
import * as BABYLON from 'babylonjs';
import BabylonScene from './babylon-scene';
import 'babylonjs-loaders';

// Babylon usage in React: http://babylonjs.com.cn/resources/babylonjs_and_reactjs.html

class Scene extends React.Component {
  onSceneMount = e => {
    const { canvas, scene, engine } = e

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new BABYLON.HemisphericLight(
      "light1",
      new BABYLON.Vector3(0, 1, 0),
      scene
    )

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 1

    if (this.props.content.field3dModel) {
      // Local Testing
      //BABYLON.SceneLoader.Append("/", `3d-test.glb`, scene, function (scene) {
      // Append glTF model to scene.
      BABYLON.SceneLoader.Append("/sites/default/files/", `${this.props.content.field3dModel.entity.fieldMediaFile1.entity.url.split("/")[this.props.content.field3dModel.entity.fieldMediaFile1.entity.url.split("/").length - 2]}/${this.props.content.field3dModel.entity.fieldMediaFile1.entity.url.split("/")[this.props.content.field3dModel.entity.fieldMediaFile1.entity.url.split("/").length - 1]}`, scene, function (scene) {
        // Create a default arc rotate camera and light.
        //scene.createDefaultCameraOrLight(true, true, true);

        // The default camera looks at the back of the asset.
        // Rotate the camera by 180 degrees to the front of the asset.
        scene.activeCamera.alpha += Math.PI;
      });
    }

    /********** ARC ROTATE CAMERA EXAMPLE **************************/

      // Creates, angles, distances and targets the camera
    var camera = new BABYLON.ArcRotateCamera("Camera", 0, 0, 10, new BABYLON.Vector3(0, 0, 0), scene);

    camera.lowerRadiusLimit = 1.25;
    camera.upperRadiusLimit = 1.25;
    // This positions the camera
    camera.setPosition(new BABYLON.Vector3(10, 0, 0));

    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);

    scene.onPointerDown = (e)=>{
      camera.lowerRadiusLimit = 1.25;
      camera.upperRadiusLimit = 1.25;
    }

    scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render()
      }
    })
  }


  render() {
    return (
      <div className="paragraph paragraph-alternative paragraph-media scene">
        <BabylonScene onSceneMount={this.onSceneMount} />
      </div>
    )
  }
}

export default Scene;
