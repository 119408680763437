import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite
});

class ParagraphText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-text": true,
      "background-color": this.props.content.fieldFarbigHinterlegen,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className={`col-16 ${!this.props.microSite ? 'col-lg-8 offset-lg-4' : ''}`}>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fieldText.processed,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphText.propTypes = {
  content: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ParagraphText);
