import React, {Component} from 'react';
import useMousePosition from "./use-mouse-position";

const CustomCursor = props => {
  // 1.
  const { x, y } = useMousePosition(props.customRef);
  return (
    <div className="custom-cursor" >
      {props.playing ? (
        <img className="play" src="/content-cloud/unmute.svg" alt="listen" style={{ left: `${x - window.innerWidth/2 + 22}px`, top: `${y + 22}px` }}/>
      ) : (
        <img className="pause" src="/content-cloud/mute.svg" alt="stop" style={{ left: `${x - window.innerWidth/2 + 22}px`, top: `${y + 22}px` }}/>
      )}
    </div>
  );
};

export default CustomCursor;