import React from "react";

function ScReg() {
  return (
    <svg width="276" height="168" viewBox="0 0 276 168">
      <path d="M0 44.91C0 59.94 11.61 66.6 26.19 66.6c14.4 0 24.93-6.48 24.93-18.99 0-10.08-6.3-15.3-17.37-18.18l-9.63-2.52c-7.65-1.89-12.51-4.23-12.51-9.63 0-5.04 4.95-8.82 12.87-8.82 9.9 0 14.58 3.87 14.58 11.25h10.53C49.14 5.49 39.24 0 25.11 0 9.99 0 1.17 7.38 1.17 18.27c0 9.09 6.12 14.22 15.93 16.74l12.78 3.51c6.84 1.71 10.8 4.59 10.8 9.99 0 6.12-5.58 9.54-14.31 9.54-9.45 0-15.48-3.87-15.66-13.14H0z"></path>
      <path d="M77.28 66.69c13.05 0 21.87-9 21.87-23.67 0-14.22-8.82-23.94-21.87-23.94-13.14 0-21.96 9.72-21.96 23.94 0 14.67 8.82 23.67 21.96 23.67zm-11.7-23.67c0-10.62 5.13-16.29 11.7-16.29 6.48 0 11.52 5.67 11.52 16.29 0 10.53-5.04 16.02-11.52 16.02-6.57 0-11.7-5.49-11.7-16.02z"></path>
      <path d="M144.48 65.34v-45h-9.81v21.33c-.09 10.35-6.12 16.56-12.78 16.56-4.77 0-8.1-3.15-8.19-10.35l-.27-27.54h-9.9l.27 30.15c.09 10.62 5.49 16.02 14.49 16.02 6.3 0 9.81-2.88 11.97-5.13 1.17-1.17 2.52-1.44 3.15-.45.72.9 1.26 2.25 1.26 4.41h9.81zM151.56 20.34v45h9.81V44.01c.09-10.35 6.12-16.56 12.78-16.56 4.77 0 8.1 3.15 8.19 10.35l.27 27.54h9.9l-.27-30.15c-.09-10.62-5.49-16.02-14.49-16.02-6.3 0-9.81 2.88-11.97 5.13-1.17 1.17-2.52 1.44-3.15.45-.72-.9-1.26-2.25-1.26-4.41h-9.81z"></path>
      <path d="M216.06 66.87c4.32 0 8.28-1.62 10.98-4.05 1.35-1.17 2.79-.72 3.24.63l.27 1.89h8.91V1.26h-9.81v19.17c0 2.7-2.25 3.06-3.78 1.98-2.34-1.71-5.13-3.24-9.63-3.24-10.62 0-19.26 8.82-19.26 23.85 0 15.48 8.64 23.85 19.08 23.85zm-9.09-23.76c0-11.07 5.4-16.38 11.43-16.38 7.38 0 11.52 5.85 11.52 16.38 0 10.62-4.14 16.2-11.52 16.2-6.48 0-11.43-5.13-11.43-16.2z"></path>
      <path d="M47.16 128.99c-1.17 6.93-7.02 11.79-15.75 11.79-11.61 0-20.07-10.44-20.07-24.75 0-13.95 8.28-24.12 19.89-24.12 9.45 0 14.76 5.67 15.66 13.05 2.88 1.35 8.28 1.35 10.08 0-.72-12.96-11.79-21.6-26.01-21.6C13.5 83.36.81 96.32.81 115.94c0 20.07 12.69 33.48 30.06 33.48 14.13 0 24.3-7.83 26.46-20.43H47.16z"></path>
      <path d="M62.7 103.34v45h9.9v-45h-9.9zm-1.08-15.57c0 3.24 2.88 6.12 6.12 6.12s6.03-2.88 6.03-6.12-2.79-6.12-6.03-6.12-6.12 2.88-6.12 6.12z"></path>
      <path d="M104.07 147.71v-7.47c-6.84 2.97-11.07.18-11.07-6.75V110.9h9.36v-7.56h-1.98c-4.68 0-7.38-2.61-7.38-7.29v-12.6h-.36l-9.45 7.92v6.48c0 3.51-2.16 5.49-5.58 5.49h-.45v7.56h.27c3.51 0 5.76 1.98 5.76 5.76v19.26c0 3.6 1.26 7.83 4.59 10.8 3.96 3.42 11.25 3.78 16.29.99zM114.39 167.42h9.81l25.29-64.08h-10.44l-7.38 20.88c-1.53 4.59-7.47 4.68-9.09 0l-7.02-20.88h-10.35l17.19 45-8.01 19.08z"></path>
    </svg>
  );
}

export default ScReg;
