import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { trunc } from "./../../lib/shorten-text";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserSocialPost extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-news teaser-social-post">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="flex-wrap"
          href={this.props.item.fieldSocialPostLink.url.path}
        >
          {this.props.item.fieldSocialPostImageFile ? (
            <img src={this.props.item.fieldSocialPostImageFile.style.url} />
          ) : (
            <img src={this.props.item.fieldSocialPostImageLink.url.path} />
          )}
          <div className="text-wrapper">
            <span className="time-since-posted">
              {moment.unix(this.props.item.created).fromNow()} - auf Instagram
            </span>
            {this.props.item.fieldSocialPostText && (
              <div
                className="teaser text"
                dangerouslySetInnerHTML={{
                  __html: trunc(
                    this.props.item.fieldSocialPostText.value,
                    250,
                    true,
                    true
                  ),
                }}
              />
            )}
          </div>
        </a>
      </article>
    );
  }
}

TeaserSocialPost.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserSocialPost));
