import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Image extends Component {
  render() {
    let { alt, title } = this.props.data;

    if (!alt && this.props.caption) {
      alt = this.props.caption;
    } else if (!alt && this.props.nodeTitle) {
      alt = this.props.nodeTitle;
    }

    if (!title && this.props.caption) {
      title = this.props.caption;
    } else if (!title && this.props.nodeTitle) {
      title = this.props.nodeTitle;
    }

    if (this.props.fullPage) {
      return (
        <>
          <img
            className={this.props.className}
            src={this.props.data.styleFullPage.url}
            title={title}
            alt={alt}
          />
          <div className="caption-credit-wrap">
            {this.props.caption && (
              <span className="caption">{this.props.caption}</span>
            )}
            {this.props.credit && (
              <span className="credit">© {this.props.credit}</span>
            )}
          </div>
        </>
      );
    }

    if (this.props.scCustomStyle) {
      return (
        <>
          <img
            className={this.props.className}
            src={this.props.data.scstyle.url}
            title={title}
            alt={alt}
          />
          {this.props.credit && (
            <span className="credit">© {this.props.credit}</span>
          )}
        </>
      );
    }

    if (this.props.data.mobile && this.props.data.desktop) {
      return [
        <img
          key="mobile-image"
          className={`${this.props.className} mobile-image d-block d-md-none`}
          src={this.props.data.mobile.url}
          title={title}
          alt={alt}
        />,
        <img
          key="desktop-image"
          className={`${this.props.className} desktop-image d-none d-md-block`}
          src={this.props.data.desktop.url}
          title={title}
          alt={alt}
        />,
      ];
    }

    return (
      <>
        <img
          className={this.props.className}
          src={this.props.data.style.url}
          title={title}
          alt={alt}
        />
        {(this.props.caption ||
          this.props.credit ||
          this.props.globalCaption) && (
          <div
            className={classNames({
              "caption-wrap": true,
              "col-16": this.props.wrapCaption,
            })}
          >
            {this.props.caption && (
              <span className="caption">{this.props.caption}</span>
            )}
            {this.props.globalCaption && !this.props.caption && (
              <span className="caption">{this.props.globalCaption}</span>
            )}
            {this.props.credit && (
              <span className="credit">© {this.props.credit}</span>
            )}
          </div>
        )}
      </>
    );
  }
}

Image.propTypes = {
  caption: PropTypes.string,
  nodeTitle: PropTypes.string,
  credit: PropTypes.string,
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  globalCaption: PropTypes.string,
  fullPage: PropTypes.bool,
  wrapCaption: PropTypes.bool,
};

export default Image;
