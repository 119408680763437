import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite
});

class ParagraphBilderSlider extends Component {
  state = {
    sliderSettings: {
      adaptiveHeight: this.props.microSite ? false : true,
      arrows: this.props.microSite ? true : false,
      dots: this.props.microSite ? true : false,
      centerMode: this.props.microSite ? true : false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: "0px",
      touchMove: false,
      infinite: true
    },
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-bilder-slider": true,
      [`format-${this.props.content.fieldFormat}`]: true,
      "single-image": this.props.content.fieldBilderWCaption.length === 1,
    });

    let sliderOrImage = <></>;

    if (this.props.content.fieldFormat === "full" && !this.props.microSite) {
      sliderOrImage = (
        <>
          <div className="full">
            <ErrorBoundary>
              <ComponentBildSlider
                wrapCaption={true}
                bilder={this.props.content.fieldBilderWCaption}
                sliderSettings={this.state.sliderSettings}
                globalCaption={this.props.content.fieldBildunterschrift}
              />
            </ErrorBoundary>
          </div>
        </>
      );
    } else {
      sliderOrImage = (
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-16": true,
                "col-lg-8 offset-lg-4 center":
                  this.props.content.fieldFormat === "center",
                "col-lg-8 left": this.props.content.fieldFormat === "left",
                "col-lg-8 offset-lg-8 right":
                  this.props.content.fieldFormat === "right",
              })}
            >
              <ErrorBoundary>
                <ComponentBildSlider
                  bilder={this.props.content.fieldBilderWCaption}
                  sliderSettings={this.state.sliderSettings}
                  globalCaption={this.props.content.fieldBildunterschrift}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      );
    }

    return <section className={sectionClassNames}>{sliderOrImage}</section>;
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ParagraphBilderSlider);
