import React, { Component } from "react";
import {
  LogoFacebook32,
  LogoFlickr32,
  LogoInstagram32,
  LogoLinkedin32,
  LogoPinterest32, LogoTumblr32, LogoTwitter32, LogoXing32,
  LogoYoutube32
} from "@carbon/icons-react";


class SocialMedia extends Component {
  render () {
    return(
      <>
        {this.props.content &&
          <div className="social-media">
            {this.props.content.fieldFacebook && (
              <a
                className="facebook"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldFacebook.url.path}
              >
                <LogoFacebook32 />
              </a>
            )}
            {this.props.content.fieldYoutube && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldYoutube.url.path}
              >
                <LogoYoutube32 />
              </a>
            )}
            {this.props.content.fieldFlickr && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldFlickr.url.path}
              >
                <LogoFlickr32 />
              </a>
            )}
            {this.props.content.fieldInstagram && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldInstagram.url.path}
              >
                <LogoInstagram32 />
              </a>
            )}
            {this.props.content.fieldVimeo && (
              <a
                className="vimeo"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldVimeo.url.path}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink={"http://www.w3.org/1999/xlink"}
                  x="0px"
                  y="0px"
                  width="32" height="32"
                  viewBox="0 0 96 96"
                  style={{ enableBackground: "new 0 0 96 96" }}
                  xmlSpace="preserve"
                >
                  <g id="XMLID_1_">
                    <path
                      id="XMLID_5_"
                      className="st0"
                      d="M15.7,35.4C22.8,29,29.1,18,36.4,20.3c12.1,3.1,6.9,26.4,13.8,37.2c9.4-5.3,15.6-16.5,13-22.3
                          c-0.6-1.6,0.1-5.7-7.6-2.1c-2,0.9-1.9-2.6-1.6-3.1c7.2-7.9,11.3-11.2,16.2-11c4.1,0.7,11.4,5.3,10.1,16.5
                          C77.6,51.9,53.2,78,45.1,77c-9.3,2.1-14-27.4-21-41.2l-6.4,2.5C15,38.7,15.4,37,15.7,35.4L15.7,35.4z"
                      fill="#333333"
                    />
                  </g>
                </svg>
              </a>
            )}
            {this.props.content.fieldLinkedin && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldLinkedin.url.path}
              >
                <LogoLinkedin32 />
              </a>
            )}
            {this.props.content.fieldPinterest && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldPinterest.url.path}
              >
                <LogoPinterest32 />
              </a>
            )}
            {this.props.content.fieldTwitter && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldTwitter.url.path}
              >
                <LogoTwitter32 />
              </a>
            )}
            {this.props.content.fieldXing && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldXing.url.path}
              >
                <LogoXing32 />
              </a>
            )}
            {this.props.content.fieldTumblr && (
              <a
                className="youtube"
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.content.fieldTumblr.url.path}
              >
                <LogoTumblr32 />
              </a>
            )}
          </div>
        }
      </>
    );
  }
}

export default SocialMedia;
