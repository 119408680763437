import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

import LoadingIndicator from "../../loading-indicator";

class ParagraphNewsletteranmeldung extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    success: false,
    successMsg: "",
  };

  onSubmit = (values, actions) => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({
        loading: false,
        success: true,
        successMsg: "Sie wurden in den Newsletter eingetragen.",
      });
      actions.setSubmitting(false);
    }, 1000);
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-newsletter-register": true,
    });

    return (
      <section className={sectionClassNames}>
        {this.state.loading && <LoadingIndicator />}

        {this.state.error && !this.state.errorMsg && (
          <div className="alert alert-danger" role="alert">
            Es ist ein Fehler aufgetreten.
          </div>
        )}

        {this.state.error && this.state.errorMsg && (
          <div className="alert alert-danger" role="alert">
            {this.state.errorMsg}
          </div>
        )}

        {this.state.success && this.state.successMsg && (
          <div className="alert alert-success" role="alert">
            {this.state.successMsg}
          </div>
        )}
        <Formik
          onSubmit={(values, actions) => {
            this.onSubmit(values, actions);
          }}
          initialValues={{
            email: "",
            consent: false,
          }}
          validationSchema={Yup.lazy((values) =>
            Yup.object().shape({
              email: Yup.string().email().required("E-Mail is required"),
              consent: Yup.bool().oneOf(
                [true],
                "Sie müssen den Bedingungen zustimmen."
              ),
            })
          )}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <div className="container">
                <div className="form-row">
                  <div className="col-16 col-md-8">
                    <Hyphenated language={de}>
                      <h3>{this.props.content.fieldHeading}</h3>
                    </Hyphenated>
                    {/*<div className="text" dangerouslySetInnerHTML={{ __html: this.props.content.fieldText.value }} />*/}
                  </div>
                  <div className="col-16 col-md-8 form-check">
                    <div className="inner-wrapper">
                      <Field
                        type="checkbox"
                        name="consent"
                        id="consent"
                        className="form-check-input"
                      />
                      <label className="form-check-label" htmlFor="consent">
                        Ja, ich bin einverstanden. Dieses Einverständnis kann
                        ich jederzeit widerrufen.
                        {errors.consent && touched.consent ? (
                          <span className="form-error text-danger">
                            {errors.consent}
                          </span>
                        ) : null}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Field
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Ihre E-Mail"
                />
                {errors.email && touched.email ? (
                  <div className="form-error text-danger">{errors.email}</div>
                ) : null}
                <div className="form-row actions">
                  <div className="col-16 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    );
  }
}

ParagraphNewsletteranmeldung.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphNewsletteranmeldung;
