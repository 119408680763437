import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Masonry from "react-masonry-component";
import { Link } from "react-router-dom";

import menuQuery from "./menu-query.graphql";
import { self } from "../config";
import ExternalInternalLink from "./link";

class FooterSitemap extends Component {
  state = {};
  render() {
    let logoPath = "";

    switch (this.props.currentNodeId) {
      case "1667":
        logoPath = "logo-vdmk.svg";
        break;
      default:
        logoPath = "logo-vdmk-red.svg";
        break;
    }

    return (
      <section className="footer-sitemap">
        <div className="row">
          <div className="col-16 col-md-6 col-lg-3 footer-logo">
            <img
              className="d-block"
              src={`${self}/${logoPath}`}
              alt="VdMK Logo"
              title="VdMK"
            />
          </div>

          <div className="col-md-16 col-lg-12">
            <Masonry className="row">
              {!this.props.mainMenuQuery.loading &&
                this.props.mainMenuQuery.menuByName.links.map((item, index) => (
                  <nav className="col-16 col-md-8 col-lg-4" key={index}>
                    <Link to={item.url.path}>{item.label}</Link>
                    {item.links.length > 0 && (
                      <ul>
                        {item.links.map((linkItem, linkIndex) => (
                          <li key={linkIndex}>
                            <ExternalInternalLink link={linkItem} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </nav>
                ))}
            </Masonry>
          </div>
        </div>
      </section>
    );
  }
}

FooterSitemap.propTypes = {
  currentNodeId: PropTypes.string.isRequired,
  mainMenuQuery: PropTypes.object.isRequired,
  menu: PropTypes.string.isRequired,
};

export default graphql(menuQuery, {
  options: (props) => ({
    variables: { name: props.menu },
  }),
  name: "mainMenuQuery",
})(FooterSitemap);
