import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import classNames from "classnames";
import { compose } from "recompose";

import teaserNodeQuery from "../../../../teaser-queries/teaser-node-query.graphql";
import teaserNodeQueryTagFiltered from "../../../../teaser-queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews from "../../../../teaser/teaser-news";
import TeaserPerson from "../../../../teaser/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import TeaserBase from "../../../../teaser-base";
import TeaserSocialPost from "../../../../teaser/teaser-social-post";

class ComponentTeaserlistWrap extends React.Component {
  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  render() {
    return (
      <>
        {this.props.nodesConfig === "Manuell" ? (
          <>
            {/* TODO: This does not work */}
            {this.props.manualNodes.map((item, index) => (
              <div
                key={index}
                className={classNames({
                  [`col-${32 / this.props.manualNodes.length} col-lg-${
                    16 / this.props.manualNodes.length
                  }`]: this.props.manualNodes.length !== 3,
                  "col-16 col-lg-5":
                    this.props.manualNodes.length === 3 && index !== 0,
                  "col-16 col-lg-5 offset-lg-1":
                    this.props.manualNodes.length === 3 && index === 0,
                })}
              >
                {(() => {
                  switch (item.entity.entityBundle) {
                    case "news":
                      return (
                        <TeaserNews
                          item={item.entity}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    case "person":
                      return (
                        <TeaserPerson
                          count={this.props.count}
                          item={item.entity}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    case "veranstaltung":
                      return (
                        <TeaserBase
                          item={item.entity}
                          single={false}
                          viewMode={"teaserlist"}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            ))}
          </>
        ) : (
          <>
            {this.props.nodes.nodeQuery &&
            this.props.nodes.nodeQuery.entities.length > 0 ? (
              <>
                {this.props.nodes.nodeQuery.entities.map((item, index) => (
                  // Would this not just be 'col-16 col-lg' ?
                  <div
                    key={index}
                    className={classNames({
                      [`col-${32 / this.props.count} col-lg-${
                        16 / this.props.count
                      }`]: this.props.count !== 3,
                      "col-16 col-lg-5": this.props.count === 3 && index !== 0,
                      "col-16 col-lg-5 offset-lg-1":
                        this.props.count === 3 && index === 0,
                    })}
                  >
                    {(() => {
                      switch (item.entityBundle) {
                        case "news":
                          return (
                            <TeaserNews
                              item={item}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "social_post":
                          return (
                            <TeaserSocialPost
                              item={item}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "person":
                          return (
                            <TeaserPerson
                              count={this.props.count}
                              item={item}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "veranstaltung":
                          return (
                            <TeaserBase
                              item={item}
                              single={false}
                              viewMode={"teaserlist"}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <LoadingIndicator />
            )}
          </>
        )}
      </>
    );
  }
}

ComponentTeaserlistWrap.propTypes = {
  count: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  manualNodes: PropTypes.array,
  nodes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  pagerFullPageOverviewLink: PropTypes.object,
  tags: PropTypes.array.isRequired,
};

export default compose(
  graphql(teaserNodeQuery, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length > 0,
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "news" ? ["news", "social_post"] : [props.type],
      },
    }),
  }),
  graphql(teaserNodeQueryTagFiltered, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length === 0,
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "news" ? ["news", "social_post"] : [props.type],
        tags: props.tags.map((item) => item.targetId.toString()),
      },
    }),
  })
)(connect()(ComponentTeaserlistWrap));
