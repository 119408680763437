import React from "react";
import { connect } from "formik";

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps,
      keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      this.props.formRef.scrollIntoView({ block: "start" });
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
