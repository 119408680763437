import React, { Component } from "react";
import {self} from "../../config";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";

class SoundCityIntro extends Component {

  scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  }


  render() {
    return (
      <div className="microsite-intro sound-city">
        <ParallaxProvider>
          <div className="parallax-wrapper">
            <Parallax translateY={[-40, 40]}>
              <img className="star" alt={"Sound City Icon"} src={`${self}/sound-city/star/star.svg`}/>
            </Parallax>
          </div>
        </ParallaxProvider>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h1 className={"sr-only"}>Sound City Festival Ludwigsfelde</h1>
              <h2 className={"date"}>25.–27.<br/>Juli 2025</h2>
            </div>
          </div>
        </div>
        <img className="scroll-down" alt={"scroll-down"} id={"scroll-down"} src={`${self}/sound-city/ui/chevron/down.svg`} onClick={() => this.scrollDown()} />
      </div>
    );
  }
}

export default SoundCityIntro;
