import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowDownRight32 } from "@carbon/icons-react";

import ComponentClickSuggestionLink from "./component-click-suggestion-link";
import ErrorBoundary from "../../../error-boundary";

class ParagraphClickSuggestions extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-click-suggestions": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h2>{this.props.content.fieldHeading}</h2>
            </div>

            <div className="col-16">
              <div className="row">
                <div className="col-16 col-md-6 offset-md-1">
                  <ul>
                    {this.props.content.fieldLinks.map((item, index) => (
                      <React.Fragment key={index}>
                        {Math.abs((index + 1) % 2) === 1 && (
                          <li>
                            <ArrowDownRight32 />
                            <ErrorBoundary>
                              <ComponentClickSuggestionLink link={item} />
                            </ErrorBoundary>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>

                <div className="col-16 col-md-6">
                  <ul>
                    {this.props.content.fieldLinks.map((item, index) => (
                      <React.Fragment key={index}>
                        {(index + 1) % 2 === 0 && (
                          <li>
                            <ArrowDownRight32 />
                            <ErrorBoundary>
                              <ComponentClickSuggestionLink link={item} />
                            </ErrorBoundary>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphClickSuggestions;
