import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import { states } from "../states";
import classNames from "classnames";

// @todo defaultValue is not working for WebformElementNumber
const NumberField = ({ formik, item, compositeIndex, compositeParent }) => {
  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    formik
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      <Field
        id={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        name={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        type="number"
        min={item.min}
        max={item.max}
        step={item.step}
        className="form-control"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={
          compositeParent
            ? formik.values[compositeParent][compositeIndex][item.id]
            : formik.values[item.id]
        }
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
      />
      <ErrorMessage component="span" name={item.id} className="error-message" />
      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

NumberField.propTypes = {
  formik: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default NumberField;
