import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ErrorBoundary from "../../../../../error-boundary";
import TeaserEventList from "./component-teaser-event-list";
import ComponentClickSuggestionLink from "../../component-click-suggestion-link";

class ParagraphTeaserListEvents extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-list": true,
      [`paragraph paragraph-teaser-list-${this.props.content.fieldTyp}`]: true,
      [`paragraph-teaser-list-${this.props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
      "paragraph paragraph-teaser-list-slick": this.props.content.fieldKarussel,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="container title">
            <div className="row">
              <div className={`col-16 col-md-15 ${!this.props.microSite ? 'offset-md-1' : ''}`}>
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <ErrorBoundary>
            <TeaserEventList
              count={
                this.props.content.fieldAnzahlDerAnzuzeigendenI
                  ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                  : 100
              }
              manualNodes={this.props.content.fieldBeitraegeVeranstaltung}
              nodesConfig={this.props.content.fieldAutmatischOderManuell}
              type={this.props.content.fieldTyp}
              tags={this.props.content.fieldSchlagwort}
              kanalFilter={this.props.content.fieldKanalfilter}
            />
          </ErrorBoundary>
        </div>

        {this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton.title && (
            <div className="container">
              <div className="row">
                <div className="col-16 d-md-flex justify-content-md-end">
                  <ErrorBoundary>
                    <ComponentClickSuggestionLink
                      link={this.props.content.fieldMehrMeldungenButton}
                      className="btn btn-primary more"
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphTeaserListEvents.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphTeaserListEvents;
