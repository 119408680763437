import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import ReactPaginate from "react-paginate";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "recompose";

import teaserNodeQuery from "../../../teaser-queries/teaser-node-query.graphql";
import teaserNodeQueryEvent from "../../../teaser-queries/teaser-node-query-event.graphql";
import teaserNodeQueryEventTagFiltered from "../../../teaser-queries/teaser-node-query-event-tag-filtered.graphql";
import TeaserPersonOverview from "../../../teaser/teaser-person-overview";
import TeaserNews from "../../../teaser/teaser-news";
import ComponentFilterCloud from "./components/component-filter-cloud";
import TeaserProjekt from "../../../teaser/teaser-projekt";
import TeaserSchule from "../../../teaser/teaser-schule";
import { pagerFullPagesAction } from "../../../../app-actions";
import ComponentExtendedTeaserOverviewFilterText from "./components/component-extended-teaser-overview-filter-text";
import ComponentExtendedTeaserOverviewFilterDialogBaum from "./components/component-extended-teaser-overview-filter-dialog-baum";
import ComponentExtendedTeaserOverviewSearch from "./components/component-extended-teaser-overview-search";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEvent from "../../../teaser/teaser-event";
import moment from "moment";
import Masonry from "react-masonry-component";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite
});

class ParagraphExtendedTeaserOverview extends Component {
  itemSection = React.createRef();

  filterItems = () => {
    let filteredItems = [];

    const activeFilter = this.state ? this.state.activeFilter : null,
      activeSearch = this.state ? this.state.activeSearch : null;

    if (
      (activeFilter && activeFilter.entityId) ||
      (activeSearch && activeSearch !== "")
    ) {
      if (activeFilter && activeFilter.entityId) {
        filteredItems = this.props.nodes.nodeQuery.entities.filter((item) =>
          item.fieldSchlagwort.some((tag) =>
            tag.entity ? tag.entity.entityId === activeFilter.entityId : false
          )
        );
      } else {
        filteredItems = this.props.nodes.nodeQuery.entities;
      }

      if (activeSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          const parsedItem = JSON.stringify(item).toLowerCase();

          return parsedItem.includes(activeSearch);
        });
      }
    } else {
      filteredItems = this.props.nodes.nodeQuery.entities;
    }

    return filteredItems;
  };

  getItemsToShow = (start, end) => {
    if (
      !this.props.nodes.loading &&
      this.props.nodes.nodeQuery.entities.length >= 1
    ) {
      if (!end) {
        end = this.props.nodes.nodeQuery.entities.length;
      }

      const items = this.filterItems();

      if (this.props.content.fieldPagerVerwenden) {
        return items.slice(start, end);
      }

      return items;
    }

    return [];
  };

  state = {
    itemsMatching: this.getItemsToShow(0),
    itemsToShow: this.getItemsToShow(
      0,
      this.props.content.fieldPagerVerwenden
        ? this.props.content.fieldElementeProSeite
        : null
    ),
    activeFilter: {},
    activeSearch: ""
  };

  pushPagerFullPageConfig = () => {
    if (
      this.props.content.fieldPagerAufVollseiten &&
      this.props.nodes.nodeQuery
    ) {
      const pagerFullPagesConfig = {
        id: this.props.content.entityId,
        items: this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.content.fieldLinkZurUebersicht,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.content.fieldPagerVerwenden
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      });
      this.pushPagerFullPageConfig();
    }
  }

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      (this.state.activeFilter.entityId === activeFilter.entityId ||
        activeFilter.entityId === "none")
    ) {
      this.setState({ activeFilter: null }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeFilter }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    }
  };

  changeActiveSearch = (searchValue) => {
    if (typeof searchValue === "undefined") {
      this.setState({ activeSearch: "" }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeSearch: searchValue }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    }
  };

  handlePageClick = (data) => {
    this.setState(
      {
        itemsToShow: this.getItemsToShow(
          data.selected * this.props.content.fieldElementeProSeite,
          data.selected * this.props.content.fieldElementeProSeite +
            this.props.content.fieldElementeProSeite
        ),
      },
      () => scroll.scrollTo(this.itemSection.current.offsetTop)
    );
  };


  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-extended-teaser-overview": true,
      [`type-${this.props.content.fieldTypExtended}`]: true
    });
    return (
      <section className={sectionClassNames} ref={this.itemSection}>
        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldFilterwolke && (
            <ErrorBoundary>
              <ComponentFilterCloud
                items={this.props.nodes.nodeQuery.entities}
                filter="fieldSchlagwort"
                activeFilter={this.state.activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
          )}

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldSucheAktivieren && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewSearch
                activeFilter={this.state.activeFilter}
                changeActiveSearch={this.changeActiveSearch}
              />
            </ErrorBoundary>
          )}

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldFilterImText && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewFilterText
                items={this.props.content.fieldFilterImTextReference}
                filter="fieldSchlagwort"
                activeFilter={this.state.activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
          )}

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldFilterDialogBaum && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewFilterDialogBaum
                items={this.props.content.fieldFilterDialogBaumReferen}
                activeFilter={this.state.activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
          )}

        <div className="container extended-teaser-container">
          <div className={`row ${this.props.content.fieldDarstellung}`}>
            {!this.props.microSite ? (
              <>
                {this.state.itemsToShow.length >= 1 &&
                  this.state.itemsToShow.map((item, index) => (
                    <React.Fragment key={index}>
                      {(() => {
                        switch (item.entityBundle) {
                          case "news":
                            return (
                              <div className="col-16 col-md-4 offset-md-1">
                                <ErrorBoundary>
                                  <TeaserNews
                                    item={item}
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                </ErrorBoundary>
                              </div>
                            );
                          case "person":
                            return (
                              <div
                                className={`col-16 col-md-8 col-lg-5 ${
                                  index === 0 || index % 3 === 0
                                    ? "offset-lg-1"
                                    : ""
                                }`}
                              >
                                <TeaserPersonOverview
                                  item={item}
                                  pagerFullPage={
                                    this.props.content.fieldPagerAufVollseiten
                                      ? this.props.content.entityId
                                      : false
                                  }
                                />
                              </div>
                            );
                          case "schule":
                          case "projekt":
                            let smallDisplay =
                              this.props.content.fieldDarstellung === "small_big" &&
                              (index === 0 ||
                                (index % 4 === 0 && (index - 2) % 2 === 0) ||
                                ((index + 1) % 4 === 0 && (index - 1) % 2 === 0));

                            return (
                              <div
                                className={classNames({
                                  "col-16 col-md-custom":
                                    (index === 0 || (index + 1) % 7 !== 0) &&
                                    this.props.content.fieldDarstellung ===
                                    "small_highlighted",
                                  "col-16 col-md-custom-highlighted":
                                    index !== 0 &&
                                    (index + 1) % 7 === 0 &&
                                    this.props.content.fieldDarstellung ===
                                    "small_highlighted",
                                  "col-md-5": smallDisplay,
                                  "col-16": true,
                                  "node-wrapper": true,
                                })}
                              >
                                {/* TODO: Rename masonry prop. */}
                                {item.entityBundle === "schule" ? (
                                  <TeaserSchule
                                    masonry={
                                      (this.props.content.fieldTypExtended ===
                                        "projekt" ||
                                        this.props.content.fieldTypExtended ===
                                        "schule") &&
                                      this.props.content.fieldDarstellung ===
                                      "small_highlighted"
                                    }
                                    item={item}
                                    bigPicture={!smallDisplay}
                                    smallHighlighted={
                                      index !== 0 &&
                                      (index + 1) % 7 === 0 &&
                                      this.props.content.fieldDarstellung ===
                                      "small_highlighted"
                                    }
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                ) : (
                                  <TeaserProjekt
                                    masonry={
                                      (this.props.content.fieldTypExtended ===
                                        "projekt" ||
                                        this.props.content.fieldTypExtended ===
                                        "schule") &&
                                      this.props.content.fieldDarstellung ===
                                      "small_highlighted"
                                    }
                                    item={item}
                                    bigPicture={!smallDisplay}
                                    smallHighlighted={
                                      index !== 0 &&
                                      (index + 1) % 7 === 0 &&
                                      this.props.content.fieldDarstellung ===
                                      "small_highlighted"
                                    }
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                )}
                              </div>
                            );
                          case "veranstaltung":
                            return <TeaserEvent item={item} />;
                          default:
                            return null;
                        }
                      })()}
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <>
                <Masonry className={'masonry-wrapper'} elementType={!this.props.microSite ? 'ul' : 'div'}>
                  {this.state.itemsToShow.length >= 1 &&
                    this.state.itemsToShow.map((item, index) => (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (item.entityBundle) {
                            case "projekt":
                              return (
                                <div
                                  className={classNames({
                                    "col-16": index === 0,
                                    "col-16 col-md-8": index > 0,
                                    "node-wrapper": true,
                                  })}
                                >
                                  <TeaserProjekt item={item}/>
                                </div>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    ))}
                </Masonry>
              </>
            )}
          </div>
        </div>

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length >
            this.props.content.fieldElementeProSeite &&
          this.props.content.fieldPagerVerwenden && (
            <div className="pager-wrapper">
              <div className="container">
                <div className="row equal">
                  <div className="col-16">
                    <div className="pagination">
                      <ReactPaginate
                        onPageChange={this.handlePageClick}
                        pageCount={Math.ceil(
                          this.state.itemsMatching.length /
                            this.props.content.fieldElementeProSeite
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        previousLabel={"Vorherige Seite"}
                        nextLabel={"Nächste Seite"}
                        previousClassName="btn btn-primary previous"
                        nextClassName="btn btn-primary next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphExtendedTeaserOverview.propTypes = {
  content: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
};

export default compose(
  // Everything except events.
  graphql(teaserNodeQuery, {
    name: "nodes",
    skip: (props) =>
      !props.content.fieldTypExtended ||
      props.content.fieldManuelleSortierung ||
      props.content.fieldTypExtended === "veranstaltung",
    options: (props) => ({
      variables: {
        limit: 10000,
        type: [props.content.fieldTypExtended],
        filterKanal: props.content.fieldKanalfilter ? true : false,
        kanal: props.content.fieldKanalfilter ? props.content.fieldKanalfilter.entity.entityId : null
      },
    }),
  }),
  graphql(teaserNodeQueryEvent, {
    name: "nodes",
    skip: (props) => props.content.fieldTypExtended !== "veranstaltung",
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: 10000,
        type: [props.content.fieldTypExtended],
        filterKanal: props.content.fieldKanalfilter ? true : false,
        kanal: props.content.fieldKanalfilter ? props.content.fieldKanalfilter.entity.entityId : null
      },
    }),
  })
)(connect(mapStateToProps)(ParagraphExtendedTeaserOverview));