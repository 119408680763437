import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

import EditButton from "../../backend/edit-button";
import { pagerFullPageContextAction } from "../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite
});

class TeaserProjekt extends Component {
  static defaultProps = {
    bigPicture: false,
    pagerFullPage: false,
  };

  render() {
    return (
      <article
        className={classNames({
          "node node-teaser teaser-projekt": true,
          promoted: this.props.item.fieldHervorgehoben,
        })}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={this.props.item.path.alias}
        >
          {this.props.item.fieldTeaserbild &&
            <div className="image-wrapper">
              <img
                src={
                  this.props.bigPicture
                    ? this.props.item.fieldTeaserbild.entity.fieldMediaImage
                      .bigPicture.url
                    : this.props.item.fieldTeaserbild.entity.fieldMediaImage.style
                      .url
                }
              />
            </div>
          }
          <Hyphenated language={de}>
            <h3>{this.props.item.title}</h3>
          </Hyphenated>
          {this.props.microSite ? (
            <>
              {this.props.item.fieldUntertitel &&
                <Hyphenated language={de}>
                  <h4>{this.props.item.fieldUntertitel}</h4>
                </Hyphenated>
              }
            </>
          ) : (
            <>
              {((this.props.item.fieldUntertitel && this.props.smallHighlighted) ||
                !this.props.masonry) && (
                <Hyphenated language={de}>
                  <h4>{this.props.item.fieldUntertitel}</h4>
                </Hyphenated>
              )}
              <div className="tags">
                {this.props.item.fieldSchlagwort.length >= 1 &&
                  this.props.item.fieldSchlagwort.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.entity !== null && (
                        <span className="tag">{item.entity.name}</span>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </>
          )}
        </Link>
      </article>
    );
  }
}

TeaserProjekt.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  bigPicture: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  masonry: PropTypes.bool,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserProjekt));
