import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Collapse } from "react-collapse";

import { trunc } from "../../lib/shorten-text";

import EditButton from "../../backend/edit-button";
import ErrorBoundary from "../../error-boundary";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite,
});

class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  state = {
    open: false,
  };

  toggleCollapse = (content) => {
    if (content.fieldBeschreibung !== null || content.fieldTermine.length > 0) {
      this.setState({ open: !this.state.open });
    }
  };

  render() {
    return (
      <article
        className={`node node-${this.props.item.entityId} ${this.props.item.fieldBild ? 'with-image' : 'without-image'} node-teaser teaser-veranstaltung col-16`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-3 offset-xl-1 date-wrapper">
              <div className="date">
                <span>
                  {moment
                    .utc(this.props.item.fieldDatum.value)
                    .local()
                    .format("DD.MM.")}
                  {this.props.item.fieldDatum.endValue &&
                    moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("DD.MM.") !==
                      moment
                        .utc(this.props.item.fieldDatum.endValue)
                        .local()
                        .format("DD.MM.") && (
                      <>
                        –{" "}
                        {moment
                          .utc(this.props.item.fieldDatum.endValue)
                          .local()
                          .format("DD.MM.")}
                      </>
                    )}
                  {this.props.microSite !== false &&
                    <> {this.props.item.fieldOrt}</>
                  }
                </span>
              </div>
            </div>
            <div
              className={`col-16 col-md-8 offset-md-1 offset-xl-0 ${
                this.props.item.fieldBeschreibung !== null ||
                this.props.item.fieldTermine.length > 0
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => this.toggleCollapse(this.props.item)}
            >
              {!this.props.microSite &&
                <div className="location">{this.props.item.fieldOrt}</div>
              }
              <h3>{this.props.item.title}</h3>
              {this.props.item.fieldText &&
                <div className="text teaser-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.item.fieldText.value,
                    }}
                  />
                </div>
              }
              <Collapse isOpened={this.state.open}>
                <>
                  {Boolean(this.props.item.fieldBeschreibung) && (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.fieldBeschreibung.value,
                      }}
                    />
                  )}
                  {this.props.item.fieldTermine.length !== 0 && (
                    <div className="times row">
                      {this.props.item.fieldTermine.map(
                        (eventItem, eventKey) => (
                          <div
                            key={eventKey}
                            className="event-item col-16 col-md-8"
                          >
                            {Boolean(
                              eventItem.entity.fieldVeranstaltungsbeschreibung
                            ) && (
                              <div
                                className="event-item-content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    eventItem.entity
                                      .fieldVeranstaltungsbeschreibung.value,
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {this.state.open && (
                    <div className="close-wrapper d-md-none">
                      <div
                        className="btn btn-secondary"
                        onClick={() => this.toggleCollapse(this.props.item)}
                      >
                        Schließen
                      </div>
                    </div>
                  )}
                </>
              </Collapse>
            </div>
            <div className="col-16 col-md-4 col-lg-3 offset-lg-1 offset-xl-0 image-col">
              {this.props.item.fieldBild !== null && (
                <Image
                  data={this.props.item.fieldBild.entity.fieldMediaImage}
                  nodeTitle={this.props.item.title}
                />
              )}
              {this.state.open && (
                <div className="close-wrapper d-none d-md-flex">
                  <div
                    className="btn btn-secondary"
                    onClick={() => this.toggleCollapse(this.props.item)}
                  >
                    Schließen
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
    );
  }
}

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserEvent));
