import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";

import Image from "../../../image";
import ErrorBoundary from "../../../../error-boundary";

const SliderPrev = (props) => {
  return (
    <div
      className="left-arrow-wrap"
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onClick={props.sliderPrevious}
    >
      <div className="slick-arrow slick-prev">
        <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />
      </div>
    </div>
  );
};

const SliderNext = (props) => {
  return (
    <div
      className="right-arrow-wrap"
      onMouseMove={props.onMouseMove}
      onMouseLeave={props.onMouseLeave}
      onClick={props.sliderNext}
    >
      <div className="slick-arrow slick-next">
        <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />
      </div>
    </div>
  );
};

/**
 * TODO: Refactor mouse sticky buttons.
 */
class ParagraphTeaserSlider extends Component {
  state = {
    sliderSettings: {
      arrows: false,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: false,
    },
  };

  slider = React.createRef();
  sliderContainer = React.createRef();

  onMouseMove = (e) => {
    const prev = this.sliderContainer.current.querySelector(".slick-prev"),
      next = this.sliderContainer.current.querySelector(".slick-next"),
      bounds = this.sliderContainer.current
        .querySelector(".hover-controls")
        .getBoundingClientRect();

    if (e.target.classList.contains("left-arrow-wrap")) {
      prev.style.top = `${e.clientY - bounds.top + 30}px`;
      prev.style.left = `${e.clientX - bounds.left}px`;
    } else if (e.target.classList.contains("right-arrow-wrap")) {
      next.style.top = `${e.clientY - bounds.top + 30}px`;
      next.style.right = `${(e.clientX - bounds.right) * -1}px`;
    }
  };

  onMouseLeave = () => {
    const prev = this.sliderContainer.current.querySelector(".slick-prev"),
      next = this.sliderContainer.current.querySelector(".slick-next");

    prev.style.removeProperty("left");
    prev.style.removeProperty("top");

    next.style.removeProperty("right");
    next.style.removeProperty("top");
  };

  sliderPrevious = () => {
    this.slider.current.slickPrev();
  };

  sliderNext = () => {
    this.slider.current.slickNext();
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-slider gallery": true,
    });

    return (
      <section className={sectionClassNames} ref={this.sliderContainer}>
        <div className="hover-controls">
          <Slider ref={this.slider} {...this.state.sliderSettings}>
            {this.props.content.fieldTeaserSlides.map((item, index) => (
              <React.Fragment key={index}>
                <Image
                  className="background-image"
                  data={item.entity.fieldImage.entity.fieldMediaImage}
                />
                <div>
                  <a href={item.entity.fieldInhaltLink.url.path}>
                    <article className="container">
                      <div className="row">
                        <div className="col-16 position-static">
                          {item.entity.fieldUntertitel && (
                            <h4>{item.entity.fieldUntertitel}</h4>
                          )}
                          {item.entity.fieldTeaserueberschrift ? (
                            <h3>{item.entity.fieldTeaserueberschrift}</h3>
                          ) : (
                            <h3>
                              {item.entity.fieldInhaltLink.url.entity.title}
                            </h3>
                          )}

                          {this.props.content.fieldTeaserSlides.length > 1 && (
                            <span className="btn btn-primary">
                              {index + 1} /{" "}
                              {this.props.content.fieldTeaserSlides.length}
                            </span>
                          )}
                        </div>
                      </div>
                    </article>
                  </a>
                </div>
              </React.Fragment>
            ))}
          </Slider>

          {this.props.content.fieldTeaserSlides.length > 1 && (
            <>
              <SliderPrev
                onMouseMove={(e) => this.onMouseMove(e)}
                onMouseLeave={() => this.onMouseLeave()}
                sliderPrevious={() => this.sliderPrevious()}
              />
              <SliderNext
                onMouseMove={(e) => this.onMouseMove(e)}
                onMouseLeave={() => this.onMouseLeave()}
                sliderNext={() => this.sliderNext()}
              />
            </>
          )}
        </div>
      </section>
    );
  }
}

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ParagraphTeaserSlider;
