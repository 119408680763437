import React, { Component } from "react";
import PropTypes from "prop-types";
import ErrorBoundary from "../../../../error-boundary";
import TeaserProgramm from "./teaser-programm";
import moment from "moment";
import groupBy from 'lodash.groupby';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {programmFilterOpenAction} from "../../../../app-actions";
import OutsideClickHandler from 'react-outside-click-handler';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {self} from "../../../../config";
import ScrollSpy from "react-scrollspy-navigation";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microSite: reduxStore.appStore.microSite,
  programmFilterOpen: reduxStore.appStore.programmFilterOpen,
});

class Programm extends Component {

  constructor(props) {
    super(props);

    this.programm = React.createRef();

    let groupedDates = groupBy(this.props.content, item => moment.utc(item.fieldDatum.startDate,  'YYYY-MM-DD HH:mm:ss A').local().format('YYYY-MM-DD'));

    this.state = {
      allFilters: [],
      allLocations: [],
      activeFilter: [],
      activeLocation: false,
      activeDayFilter: 0,
      groupedDates,
      initial: true,
      shownDates: this.props.content,
      showFilterOverlay: false,
      scrollOptions : {
        reserveScrollBarGap: true,
        allowTouchMove: el => {
          while (el && el !== document.body) {
            if (el.classList.contains('body-scroll-lock-ignore')) {
              return true;
            }
            el = el.parentElement;
          }
        }
      }
    };
  }

  getAvailableFilters = () => {
    let allFilters = [],
    allLocations = [];

    this.state.shownDates.map(function(event) {
      if (event.fieldSchlagwort) {
        allFilters.push(...event.fieldSchlagwort);
      }
      if (event.fieldBuehne) {
        allLocations.push(event.fieldBuehne);
      }
    });

    // Make the possbile filters Unique https://stackoverflow.com/questions/51537568/how-to-get-unique-values-from-object-array-javascript
    allFilters = Array.from(new Set(allFilters.map(JSON.stringify))).map(JSON.parse);
    allFilters = allFilters.sort((a, b) => a.entity.name.localeCompare(b.entity.name));

    allLocations = Array.from(new Set(allLocations.map(JSON.stringify))).map(JSON.parse);
    allLocations = allLocations.sort((a, b) => a.entity.name.localeCompare(b.entity.name));

    this.setState({
      initial: false,
      allFilters,
      allLocations
    });
  }

  // This function toggles the filter overlay
  toggleFilterOverlay = () => {
    // @todo: Does not scroll properly on iOS. Use the correct implementation. disabled as hotfix
    /*if (this.props.programmFilterOpen) {
      enableBodyScroll(this.programm.current.querySelector('.filter-overlay .inner-wrapper'), this.state.scrollOptions);
    } else {
      disableBodyScroll(this.programm.current.querySelector('.filter-overlay .inner-wrapper'), this.state.scrollOptions);
    }*/
    this.props.dispatch(programmFilterOpenAction(!this.props.programmFilterOpen));
    // Stop Body scrolling
  }

  // Filter Functionality for all Tags
  filterForTag = (what, activeFilter) => {
    let shownDates = what.filter((item) => {
      return item.fieldSchlagwort.some((tag) => tag.entity ? tag.entity.entityId === activeFilter.entity.entityId : false);
    });
    this.setState({
      shownDates
    },() => {
      this.getAvailableFilters();
      window.scrollTo({
        top: 0
      });
    });
  }

  // Filter Functionality for Locations
  filterForLocation = (what, activeLocation) => {
    let shownDates = what.filter((item) => {
      return item.fieldBuehne && item.fieldBuehne.entity.entityId === activeLocation.entity.entityId;
    });
    this.setState({
      shownDates
    },() => {
      this.getAvailableFilters();
      window.scrollTo({
        top: 0
      });
    });
  }

  // This function filters several values, when its given the type and the content to filter for.
  filterContent = (baseContent) => {
    if (this.state.activeFilter && this.state.activeFilter.length > 0) {
      this.state.activeFilter.map((activeFilter, i) => {
        this.filterForTag(baseContent, activeFilter);
      });
    } else {
      // Reset Filters if no tag filter is set
      this.setState({
        shownDates: this.props.content
      },() => {

        this.getAvailableFilters();
      });
    }
    if (this.state.activeLocation) {
      this.filterForLocation(baseContent, this.state.activeLocation);
    }
  }

  setTag = (filter) => {
    let activeFilter = this.state.activeFilter,
    activeLocation = this.state.activeLocation;

    if (filter.entity.entityBundle === "buehnen") {
      if (activeLocation && (activeLocation.entity.entityId === filter.entity.entityId)) {
        activeLocation = false;
        this.setState({
          activeLocation
        },() => {
          // Reset base content to all Events
          this.filterContent(this.props.content);
        })
      } else {
        this.setState({
          activeLocation: filter,

        },() => {
          // Work with shown events and filter them
          this.filterContent(this.state.shownDates);
        })
      }
    } else {
      if (activeFilter.some(item => item.entity && filter.entity && item.entity.entityId === filter.entity.entityId)) {
        const index = activeFilter.indexOf(filter);

        if (index >= -1) { // only splice array when item is found
          activeFilter.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setState({
          activeFilter
        },() => {
          // Reset base content to all Events
          this.filterContent(this.props.content);
        })
      } else {
        activeFilter.push(filter);
        this.setState({
          activeFilter,
        },() => {
          // Work with shown events and filter them
          this.filterContent(this.state.shownDates);
        })
      }
    }
  }

  resetFilter = () => {
    this.setState({
      activeLocation: false,
      activeFilter: [],
    },() => {
      // Reset base content to all Events
      this.filterContent(this.props.content);
    })
  }

  componentDidMount() {
    if (this.state.initial) {
      this.getAvailableFilters();
    }
  }

  componentWillUnmount() {
    // Clear Scroll lock on page change
    enableBodyScroll(this.programm.current.querySelector('.filter-overlay .inner-wrapper'), this.state.scrollOptions);
  }

  render() {
    return (
      <div className={`programm ${this.props.programmFilterOpen ? 'show-filters' : ''}`} ref={this.programm}>
        <div className={"head"}>
          <div className="container">
            <div className={"row"}>
              <div className="col-16">
                {this.state.groupedDates &&
                  <ul className="day-filter">
                    <li className="day month-indicator">
                      <span>
                        {moment
                          .utc(Object.keys(this.state.groupedDates)[0],  'YYYY-MM-DD')
                          .local()
                          .format("MMMM")}
                      </span>
                    </li>
                    <ScrollSpy className="active" offset={200}>
                      {Object.keys(this.state.groupedDates).map((keyName, i) => (
                          <a href={`#day-${moment.utc(keyName,  'YYYY-MM-DD').local().format("DD-MM-YYYY")}`} ref={React.createRef()} key={i} className="day selectable">
                            <span className="day-select">
                              {moment
                                .utc(keyName,  'YYYY-MM-DD')
                                .local()
                                .format("ddd D")}
                            </span>
                          </a>
                      ))}
                    </ScrollSpy>
                  </ul>
                }
              </div>
            </div>
          </div>
          <div className="filter-wrapper">
            <div className="container">
              <div className={"row"}>
                <div className="col-16">
                  <div className="tag-filter">
                    <button className="label" onClick={() => this.toggleFilterOverlay()} aria-label={"filter öffnen"}>
                      Filter
                    </button>
                    <div className="filter-infos">
                      <span className={`active-filters ${(this.state.activeFilter.length + (this.state.activeLocation ? 1 : 0)) > 0 ? 'active' : 'hidden'}`}>
                      {this.state.activeFilter.length + (this.state.activeLocation ? 1 : 0)} Filter
                    </span>
                      <button className={`label reset-button ${(this.state.activeFilter.length + (this.state.activeLocation ? 1 : 0)) > 0 ? 'active' : 'hidden'}`} aria-label={"filter zuruecksetzen"} onClick={() => this.resetFilter()}>
                        zurücksetzen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="events-wrapper">
          {/* This is done to properly structure the data for scrollspy. We loop through the given Days using the content object and its keys.
            * Then we filter the filtered results for the day we want to put it into.*/}
          {this.props.download && this.props.download.entity && this.props.download.entity.fieldMediaFile &&
            <div className="download-wrapper">
              <a download href={this.props.download.entity.fieldMediaFile.entity.url} target={"_blank"}>Programm als PDF</a>
            </div>
          }
          {Object.keys(this.state.groupedDates).map((keyName, index) => (
            <div className="day-wrapper" id={`day-${moment(keyName,  'YYYY-MM-DD').format("DD-MM-YYYY")}`} key={index}>
              {this.state.shownDates && this.state.shownDates.filter(item => moment(item.fieldDatum.startDate,  'YYYY-MM-DD HH:mm:ss A').format("DD-MM-YYYY") === moment(keyName).format("DD-MM-YYYY")).map((item, index) => (
                <ErrorBoundary key={index}>
                  <TeaserProgramm item={item}/>
                </ErrorBoundary>
              ))}
            </div>
          ))}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            if(this.props.programmFilterOpen) {
              this.props.dispatch(programmFilterOpenAction(false));
              enableBodyScroll(this.programm.current.querySelector('.filter-overlay .inner-wrapper'), this.state.scrollOptions);
            }
          }}
        >
          <div className="filter-overlay">
            <div className="inner-wrapper">
              <div className={"container"}>
                <div className={"row"}>
                  <div className="col-16 reset-wrapper">
                    <button onClick={() => this.resetFilter()}>
                      zurücksetzen
                    </button>
                    <div className="fix-close-button">
                      <button className="close-button" onClick={() => this.toggleFilterOverlay()}>
                        <span className="sr-only">schließen</span>
                        <img alt={"Schließen Icon"} src={`${self}/sound-city/ui/ui-close.svg`} />
                      </button>
                    </div>
                  </div>
                  <div className="col-16 filter-option-wrapper tag">
                    <p className="label">Genres</p>
                    <ul>
                      {this.state.allFilters.map((filter, index) => (
                        <li key={index} className={this.state.activeFilter.some(activeFilter => activeFilter.entity.entityId === filter.entity.entityId) ? 'active' : ''} onClick={() => this.setTag(filter)}>
                          {filter.entity.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-16 filter-option-wrapper location">
                    <p className="label">Orte</p>
                    <ul>
                      {this.state.allLocations.map((filter, index) =>
                        <li key={index} className={this.state.activeLocation && this.state.activeLocation.entity.entityId === filter.entity.entityId ? 'active' : ''} onClick={() => this.setTag(filter)}>
                          {filter.entity.name}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    );
  }
}

Programm.propTypes = {
  content: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(withRouter(Programm));
