import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import { self } from "../config";
import menuQuery from "./menu-query.graphql";
import { connect } from "react-redux";
import { morphedLogoAction } from "../app-actions";
import SocialMedia from "../general-components/social-media";
import ExternalInternalLink from "../general-components/link";

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite,
  auth: reduxStore.auth,
});

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubmenu: false,
      activeThirdLevel: false,
      menuOpen: false,
      menuSmall: false,
      logoSmall: false,
      mobileHeight: null,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.mobileResize = this.mobileResize.bind(this);
  }

  toggleFullScreenMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      if (this.state.menuOpen) {
        document.body.classList.add("fullscreen-menu-open");
        document
          .getElementsByTagName("html")[0]
          .classList.add("fullscreen-menu-open");
      } else {
        document.body.classList.remove("fullscreen-menu-open");
        document
          .getElementsByTagName("html")[0]
          .classList.remove("fullscreen-menu-open");
        setTimeout(() => this.setState({ activeSubmenu: false }), 250);
      }
    });
  };

  toggleThirdLevel = (index) => {
    if (this.state.activeThirdLevel !== index) {
      this.setState({ activeThirdLevel: index });
    } else {
      this.setState({ activeThirdLevel: false });
    }
  };

  handleScroll() {
    if (window.scrollY > 25) {
      document.getElementById("site-header").classList.add("small");
      document.body.classList.add("menu-small");
      if (!this.state.menuSmall) {
        this.setState({ menuSmall: true });
      }
    } else if (this.state.menuSmall) {
      this.setState({ menuSmall: false });
      document.getElementById("site-header").classList.remove("small");
      document.body.classList.remove("menu-small");
    }

    // Logo Morph animation, only on front page
    if (this.props.location.pathname === "/") {
      if (window.scrollY > window.innerHeight - 300) {
        document.getElementById("site-header").classList.add("logo-small");
        if (!this.state.logoSmall) {
          this.setState({ logoSmall: true });
        }
        if (!this.props.morphedLogo) {
          this.props.dispatch(morphedLogoAction(true));
        }
      } else if (this.state.logoSmall) {
        this.setState({ logoSmall: false });
        if (this.props.morphedLogo) {
          this.props.dispatch(morphedLogoAction(false));
        }
        document.getElementById("site-header").classList.remove("logo-small");
      }
    }
  }

  mobileResize() {
    if (window.innerHeight > this.state.mobileHeight) {
      //Browser bottom bar not shown
      document.getElementById("app").classList.remove("with-browser-bar");
    } else {
      //Browser bottom bar shown
      document.getElementById("app").classList.add("with-browser-bar");
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (window.innerWidth < 554) {
      window.addEventListener("resize", this.mobileResize);
      this.setState({ mobileHeight: window.innerHeight });
      document.getElementById("app").classList.add("with-browser-bar");
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.pathname !== "/") {
        this.props.dispatch(morphedLogoAction(true));
      }
    }
  };

  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-8 logo d-none d-lg-block">
              {this.props.microSite ? (
                <a className="site-logo-button" href="/">
                  Sound City Festival
                </a>
              ) : (
                <a className="site-logo-button" href="/">
                  Verband der Musik- und <br /> Kunstschulen Brandenburg
                </a>
              )}
            </div>
            <div className="col-3 col-md-5 col-lg-6 d-none d-lg-block menu-info">
              Menü
            </div>
            <div
              id="toggle-fullscreen-menu-back"
              className={`col-3 col-sm-2 col-md-3 col-lg-2 ${
                this.state.menuOpen ? "active" : ""
              } ${this.state.activeSubmenu !== false ? "active-sub" : ""}`}
              onClick={() => this.setState({ activeSubmenu: false })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="27"
                height="27"
                viewBox="0 0 27 27"
              >
                <defs>
                  <path
                    id="a"
                    d="M22 1v17h-1.889v-.001L5 18v-1.889h15.111V1H22z"
                  ></path>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <mask fill="#fff">
                    <use xlinkHref="#a"></use>
                  </mask>
                  <use
                    fill="#ff1038"
                    transform="rotate(45 13.5 9.5)"
                    xlinkHref="#a"
                  ></use>
                </g>
              </svg>
            </div>
            <div
              id="toggle-fullscreen-menu"
              className={`col-3 col-sm-2 col-md-3 col-lg-2 ${
                this.state.menuOpen ? "active" : ""
              } ${this.state.activeSubmenu !== false ? "active-sub" : ""}`}
              onClick={() => this.toggleFullScreenMenu()}
            >
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar4" />
            </div>
          </div>
        </div>
        <div
          id="fullscreen-menu"
          className={`${
            this.state.activeSubmenu !== false ? "active-submenu" : ""
          } ${this.state.menuOpen ? "active" : ""}`}
        >
          <div className="fix-wrapper">
            {this.props.microSite === "sound-city" && (
              <video
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                src={`${self}/sound-city/menu_video_2024.mp4`}
              />
            )}
            <div className="nav-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-16 col-md-8 logo">
                    {this.props.microSite ? (
                      <img
                        className="d-none d-md-block"
                        src={`${self}/sound-city/logos/sound-city-regular-basic.svg`}
                        alt="Sound City Festival Logo"
                        title="Sound City Festival"
                      />
                    ) : (
                      <img
                        className="d-none d-md-block"
                        src={`${self}/logo-vdmk.svg`}
                        alt="VdMK Logo"
                        title="VdMK"
                      />
                    )}
                  </div>
                  <div className="col-16 col-lg-8 secondary" />
                  <div
                    className={`col-16 first-level ${
                      this.props.microSite ? "" : "col-lg-8"
                    }`}
                  >
                    <nav className="main-menu-desktop">
                      <ul>
                        {this.props.mainMenuQuery &&
                          !this.props.mainMenuQuery.loading &&
                          this.props.mainMenuQuery.menuByName.links.map(
                            (item, index) => (
                              <li
                                onClick={() =>
                                  item.url.path !== ""
                                    ? this.toggleFullScreenMenu()
                                    : ""
                                }
                                className="row"
                                key={index}
                              >
                                <div className="col-16 offset-md-1 offset-lg-2 col-md-14">
                                  {item.url.path !== "" ? (
                                    <Link
                                      to={item.url.path}
                                      className={`${
                                        item.label === "Interner Bereich"
                                          ? "internal"
                                          : ""
                                      }${
                                        this.state.activeSubmenu === index
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {item.label}
                                    </Link>
                                  ) : (
                                    <span
                                      className={`${
                                        item.label === "Interner Bereich"
                                          ? "internal-main-link"
                                          : ""
                                      }${
                                        this.state.activeSubmenu === index
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.setState({ activeSubmenu: index })
                                      }
                                    >
                                      {item.label}
                                    </span>
                                  )}
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    </nav>
                    <div className="col-6 d-md-none mobile-menu-logo">
                      {!this.props.microSite ? (
                        <img
                          className=""
                          src={`${self}/logo-vdmk.svg`}
                          alt="VdMK Logo"
                          title="VdMK"
                        />
                      ) : (
                        <img
                          className=""
                          src={`${self}/sound-city/logos/sound-city-regular-basic.svg`}
                          alt="VdMK Logo"
                          title="VdMK"
                        />
                      )}
                    </div>
                    {!this.props.microSite &&
                      this.props.oftenClickedMenuQuery &&
                      !this.props.oftenClickedMenuQuery.loading && (
                        <div className="often-clicked d-none d-md-flex col-md-8">
                          <div className="col-16">
                            <h2>
                              {this.props.oftenClickedMenuQuery.menuByName.name}
                            </h2>
                            <nav className="often-clicked">
                              <ul>
                                {this.props.oftenClickedMenuQuery.menuByName.links.map(
                                  (item, index) => (
                                    <li
                                      onClick={() =>
                                        this.toggleFullScreenMenu()
                                      }
                                      key={index}
                                    >
                                      <Link to={item.url.path}>
                                        {item.label}
                                      </Link>
                                    </li>
                                  )
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      )}
                    {!this.props.microSite && (
                      <div className="internal col-md-8 d-none d-lg-flex">
                        <a
                          href="https://www.vdmk-brandenburg.de/intern/login"
                          className="col-16"
                        >
                          intern
                        </a>
                      </div>
                    )}
                    <div className="internal-log-out col-md-8 d-none d-lg-flex">
                      <a href="/user/logout" className="col-16">
                        Logout
                      </a>
                    </div>
                  </div>
                  {this.props.microSite !== false && (
                    <div className="col-16 social-and-footer">
                      {this.props.footerMenu && (
                        <ul className="menu-footer">
                          {this.props.footerMenu.menuByName &&
                            this.props.footerMenu.menuByName.links &&
                            this.props.footerMenu.menuByName.links.map(
                              (item, index) => (
                                <li
                                  key={index}
                                  onClick={() => this.toggleFullScreenMenu()}
                                >
                                  <ExternalInternalLink link={item} />
                                </li>
                              )
                            )}
                        </ul>
                      )}
                      <SocialMedia content={this.props.footerConfig} />
                    </div>
                  )}
                  <div className="col-16 col-lg-8 second-level">
                    {this.props.mainMenuQuery &&
                      !this.props.mainMenuQuery.loading &&
                      this.state.activeSubmenu !== false &&
                      typeof this.props.mainMenuQuery.menuByName.links[
                        this.state.activeSubmenu
                      ] !== "undefined" && (
                        <ul className={"offset-md-1 offset-lg-2"}>
                          {this.props.mainMenuQuery.menuByName.links[
                            this.state.activeSubmenu
                          ].links.map((linkItem, linkIndex) => (
                            <li
                              onClick={() => this.toggleThirdLevel(linkIndex)}
                              key={linkIndex}
                              className={
                                linkItem.links.length >= 1 ? "has-submenu" : ""
                              }
                            >
                              {linkItem.url.path !== "" ? (
                                <Link
                                  onClick={() => this.toggleFullScreenMenu()}
                                  to={linkItem.url.path}
                                  className={
                                    window.location.pathname ===
                                    linkItem.url.path
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {linkItem.label}
                                </Link>
                              ) : (
                                <span
                                  className={
                                    this.state.activeThirdLevel === linkIndex
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    this.setState({
                                      activeThirdLevel: linkIndex,
                                    })
                                  }
                                >
                                  {linkItem.label}
                                </span>
                              )}
                              {linkItem.links.length >= 1 && (
                                <ul
                                  className={
                                    this.state.activeThirdLevel === linkIndex
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {linkItem.links.map(
                                    (linkSubItem, linkSubIndex) => (
                                      <li
                                        onClick={() =>
                                          this.toggleFullScreenMenu()
                                        }
                                        key={linkSubIndex}
                                      >
                                        <Link to={linkSubItem.url.path}>
                                          {linkSubItem.label}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SimpleNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
  oftenClickedMenuQuery: PropTypes.object,
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: props.menuName,
          t: props.auth.userLoginData.logout_token,
        },
        fetchPolicy: "network-only",
      }),
      shouldResubscribe: (props, nextProps) => {
        return (
          props.auth.userLoginData.logout_token !==
          nextProps.auth.userLoginData.logout_token
        );
      },
      name: "mainMenuQuery",
    }),
    graphql(menuQuery, {
      options: {
        variables: { name: "haeufig-geklickt" },
      },
      name: "oftenClickedMenuQuery",
    })
  )(SimpleNavigation)
);
